import { Component, OnInit } from '@angular/core';

import { ErrorType } from '../error-page/error-page.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  defaultColor: ErrorType = ErrorType.Info;
  constructor() {}

  ngOnInit() {}
}
