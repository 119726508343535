import { Action } from '@eceos/arch';
export class VaccineApplicationsActions implements Action {
  constructor(public readonly id: string) {}

  toJson() {
    return this.id;
  }

  static readonly REQUEST = new VaccineApplicationsActions('REQUEST');
  static readonly PAY = new VaccineApplicationsActions('PAY');
  static readonly TO_APPLY = new VaccineApplicationsActions('TO_APPLY');
  static readonly CANCEL_APPLICATION = new VaccineApplicationsActions('CANCEL_APPLICATION');
  static readonly DISCOUNT = new VaccineApplicationsActions('DISCOUNT');
  static readonly PAYMENT_COVENANT = new VaccineApplicationsActions('PAYMENT_COVENANT');

  static readonly ALL = [
    VaccineApplicationsActions.REQUEST,
    VaccineApplicationsActions.PAY,
    VaccineApplicationsActions.TO_APPLY,
    VaccineApplicationsActions.CANCEL_APPLICATION,
    VaccineApplicationsActions.DISCOUNT,
    VaccineApplicationsActions.PAYMENT_COVENANT
  ];
}
