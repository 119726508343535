import { Action } from '@eceos/arch';
export class CrudAction implements Action {
  constructor(public readonly id: string) {}

  toJson() {
    return this.id;
  }
}

export const INSERT = new CrudAction('INSERT');
export const UPDATE = new CrudAction('UPDATE');
export const DELETE = new CrudAction('DELETE');
export const DELETE_TEMPORARY = new CrudAction('DELETE_TEMPORARY');

