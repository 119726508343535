<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <mat-card class="loginCard">
    <mat-card-title>Autenticação</mat-card-title>
    <form class="fields">
      <mat-form-field thin>
        <mat-label> {{ usernameInputLabel }}</mat-label>
        <input
          matInput
          #username
          type="email"
          (keydown.enter)="submitLogin(username.value, password.value)"
        />
      </mat-form-field>
      <mat-form-field thin>
        <mat-label>Senha</mat-label>
        <input
          matInput
          #password
          type="password"
          (keydown.enter)="submitLogin(username.value, password.value)"
        />
      </mat-form-field>
      <div class="forgotPassword" *ngIf="canRecoveryPassword">
        <a [routerLink]="forgotPasswordPath"><small>Esqueci minha senha</small></a>
      </div>
    </form>
    <div class="signUp" *ngIf="canSignUp">
      <a [routerLink]="signUpPath"><small>Cadastre-se</small></a>
    </div>
    <mat-card-actions>
      <button
        #enterBtn
        mat-raised-button
        class="ok-btn"
        [disabled]="loading"
        (click)="submitLogin(username.value, password.value)"
      >
        Entrar
      </button>
    </mat-card-actions>
  </mat-card>
</section>
