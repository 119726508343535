import JWT from 'jwt-decode';

export interface Token {
  token: string;
}

export class EceosToken implements Token {
  public token: string;
  constructor(public authenticatedId: string, public tenantId: string, public created: Date) {}

  static decode(token: string): EceosToken {
    if (!token) {
      return null;
    }
    const decodedToken = JWT<EceosToken>(token);
    decodedToken.token = token;
    return decodedToken;
  }
}

export class ManagerToken implements Token {
  public token: string;
  constructor(
    public authenticatedId: string,
    public email: string,
    public type: string,
    public confirmed: boolean,
    public created: Date
  ) {}

  static decode(token: string): ManagerToken {
    if (!token) {
      return null;
    }
    const decodedToken = JWT<ManagerToken>(token);
    decodedToken.token = token;
    return decodedToken;
  }
}
