import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '../../http/http-status-code';

export abstract class HttpErrorHandler {
  constructor(readonly priority = 0) {}

  abstract matches(error: ErrorResponse): boolean;
  abstract handle(error: ErrorResponse): void;
}

export class ErrorResponse {
  constructor(
    readonly code: number,
    readonly error: HttpErrorResponse,
    readonly message: string,
    readonly constraintName: string
  ) {}

  get isClientError(): boolean {
    return this.code >= 400 && this.code <= 499;
  }

  get isServerError(): boolean {
    return this.code >= 500 && this.code <= 599;
  }

  get isUnauthorizedError(): boolean {
    return this.code == HttpStatusCode.UNAUTHORIZED;
  }

  static from(errorResponse: HttpErrorResponse) {
    return errorResponse
      ? new ErrorResponse(
          errorResponse.error.code || errorResponse.status,
          errorResponse,
          this.parseErrorMessage(errorResponse.error),
          this.parseErrorConstraint(errorResponse.error)
        )
      : null;
  }

  private static parseErrorConstraint(error: any | any[]): string {
    if (!error) {
      return null;
    } else if (Array.isArray(error)) {
      return error
        .map((e) => e.constraintName)
        .filter((m) => this.isNotEmpty(m))
        .join(' | ');
    } else if (this.isNotEmpty(error.constraintName)) {
      return error.constraintName;
    } else {
      return null;
    }
  }

  private static parseErrorMessage(error: any | any[]): string {
    if (!error) {
      return null;
    } else if (Array.isArray(error)) {
      return error
        .map((e) => e.message || e.error)
        .filter((m) => this.isNotEmpty(m))
        .join(' | ');
    } else if (this.isNotEmpty(error.message || error.error)) {
      return error.message || error.error;
    } else {
      return null;
    }
  }

  private static isNotEmpty(string: string): boolean {
    return string && typeof string === 'string' && string.trim().length > 0;
  }
}
