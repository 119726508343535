import { Injectable } from '@angular/core';
import { ApiPath, ApiService, AuthService } from '@eceos/arch';
import { retryExceptOnClientError } from '@eceos/common-utils';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, mergeMap, retryWhen, shareReplay, tap } from 'rxjs/operators';
import { CurrentInfo } from './current-info';

const CURRENT_CACHE_KEY = 'eceos.cache.current';

@Injectable({
  providedIn: 'root'
})
export class CurrentInfoService {
  public readonly $value: Observable<CurrentInfo>;

  private readonly $cacheValue = new Subject<CurrentInfo>();

  private target: ApiPath;

  constructor(authService: AuthService, api: ApiService) {
    this.target = api.root.path('current');
    authService.$hasToken.subscribe((hasToken) => {
      if (!hasToken) this.cleanCache();
    });
    this.$value = merge(
      this.$cacheValue,
      authService.$hasToken.pipe(
        debounceTime(100),
        filter((hasToken) => hasToken),
        mergeMap(() => this.fetchCurrent()),
        tap((v) => this.saveToCache(v))
      )
    ).pipe(shareReplay(1));

    const cacheValue = this.loadFromCache();

    if (cacheValue) {
      this.$cacheValue.next(cacheValue);
    }
  }

  private fetchCurrent(): Observable<CurrentInfo> {
    return this.target
      .path('/full')
      .getJson()
      .pipe(
        map((j) => CurrentInfo.fromJson(j)),
        retryWhen(retryExceptOnClientError())
      );
  }

  private loadFromCache(): CurrentInfo {
    const str = localStorage.getItem(CURRENT_CACHE_KEY);
    if (str) {
      return CurrentInfo.fromJson(JSON.parse(atob(str)));
    }
    return null;
  }

  private cleanCache() {
    localStorage.removeItem(CURRENT_CACHE_KEY);
  }

  private saveToCache(value: CurrentInfo) {
    localStorage.setItem(CURRENT_CACHE_KEY, btoa(JSON.stringify(value.toJson())));
  }
}
