import { JsonEntity, SgvJson } from '@eceos/arch';
import { Authenticated } from '../core/authenticated';
import { EnterpriseSummary } from '../enterprise';
import { Partner } from '../partner';

export interface Principal {
  readonly user: string;
  readonly tenant: string;
}

export class CurrentInfo implements JsonEntity {
  constructor(
    public authenticated: Authenticated = null,
    public enterprise: EnterpriseSummary = null,
    public partner: Partner = null
  ) {}

  get principal(): Principal {
    return {
      user: this.authenticated.identifier,
      tenant: this.enterprise.identifier
    };
  }

  get principalStr() {
    if (this.authenticated.isExternal) {
      return this.authenticated.identifier;
    } else {
      const p = this.principal;
      return p ? `${p.user}@${p.tenant}` : '';
    }
  }

  toJson() {
    return SgvJson.to.simple(this, {
      authenticated: this.authenticated.toJson(),
      enterprise: this.enterprise.toJson(),
      partner: SgvJson.to.optional(this.partner)
    });
  }

  static fromJson(json: any): CurrentInfo {
    if (!json) {
      return null;
    }

    return SgvJson.from.simple(json, CurrentInfo, {
      authenticated: Authenticated.fromJson(json.authenticated),
      enterprise: EnterpriseSummary.fromJson(json.enterprise),
      partner: Partner.fromJson(json.partner)
    });
  }
}
