import { FormulaVariable } from '../../core/formula-executor/formula-executor';
export class BcVariable implements FormulaVariable {
  static QUANTITY = new BcVariable('$QUANTIDADE');
  static UNITARY_VALUE = new BcVariable('$VALOR_UNITARIO');
  static FREIGHT_VALUE = new BcVariable('$VALOR_FRETE');
  static INSURANCE_VALUE = new BcVariable('$VALOR_SEGURO');
  static OTHERS_VALUE = new BcVariable('$VALOR_OUTRAS_DESPESAS_ACESSORIAS');
  static DISCOUNT_VALUE = new BcVariable('$VALOR_DESCONTO');
  static BC_REDUCTION_PERCENT = new BcVariable('$PORCENTAGEM_REDUCAO_BC');
  static BC_REDUCTION_ST_PERCENT = new BcVariable('$PORCENTAGEM_REDUCAO_BC_ST');
  static PIS_VALUE = new BcVariable('$VALOR_PIS');
  static COFINS_VALUE = new BcVariable('$VALOR_COFINS');
  static II_CUSTOM_CHARGE_VALUE = new BcVariable('$VALOR_DESPESAS_ADUANEIRAS_II');
  static MVA_PERCENT = new BcVariable("$PORCENTAGEM_MVA");
  static IPI_VALUE = new BcVariable("$VALOR_IPI");
  static ICMS_ALIQUOT = new BcVariable("$ALIQUOTA_ICMS");

  private constructor(readonly param: string) {}

  static values(): BcVariable[] {
    return [
      BcVariable.QUANTITY,
      BcVariable.UNITARY_VALUE,
      BcVariable.FREIGHT_VALUE,
      BcVariable.INSURANCE_VALUE,
      BcVariable.OTHERS_VALUE,
      BcVariable.DISCOUNT_VALUE,
      BcVariable.BC_REDUCTION_PERCENT,
      BcVariable.BC_REDUCTION_ST_PERCENT,
      BcVariable.PIS_VALUE,
      BcVariable.COFINS_VALUE,
      BcVariable.II_CUSTOM_CHARGE_VALUE,
      BcVariable.MVA_PERCENT,
      BcVariable.IPI_VALUE,
      BcVariable.ICMS_ALIQUOT
    ];
  }
}
