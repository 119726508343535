import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';


export class DoseSummary implements DomainEntity {
  public readonly id = SgvId.gen();
  name: string;

  static fromJson(json: any): DoseSummary {
    return json ? SgvJson.from.simple(json, DoseSummary) : null;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
}
