import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cookie-snackbar',
  templateUrl: './cookie-snackbar.component.html',
  styleUrls: ['./cookie-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieSnackBarComponent implements OnInit {
  constructor(public snackBar: MatSnackBar) {}

  ngOnInit() {}

  onOkClick() {this.snackBar.dismiss();}
}
