
export class FiscalDocumentType {

  public static IN = new FiscalDocumentType('IN', 'Entrada');
  public static OUT = new FiscalDocumentType('OUT', 'Saida');

  private constructor(
    public readonly name: string,
    public readonly label: string
  ) {}

  public static values(): FiscalDocumentType[] {
    return [
      FiscalDocumentType.IN,
      FiscalDocumentType.OUT
    ];
  }

  public static get(name: String): FiscalDocumentType {
    const fiscalDocumentType = FiscalDocumentType.values().find(v => v.name === name);
    if (!fiscalDocumentType) {
      const err = new Error(`${name} FiscalDocumentType is not mapped!`);
      console.error(err);
      throw err;
    }
    return fiscalDocumentType;
  }
}
