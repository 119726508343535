<mat-sidenav-container>
  <mat-sidenav class="menu" mode="over" [(opened)]="menuOpened">
    <mat-toolbar (swipeleft)="menuOpened = false" color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="toggleMenu()"><mat-icon>arrow_back</mat-icon></button>
        <mat-icon class="mat-icon-logo" svgIcon="assets:logo_short_eceos_white"></mat-icon>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <button mat-icon-button>
          <mat-icon svgIcon="user-circle"></mat-icon>
        </button>
        <div class="user">
          <h4 class="name">{{ (currentUser.$user | async)?.name }}</h4>
          <small class="identifier">{{ currentUser.$principalStr | async }}</small>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/vaccines/">
        <mat-icon mat-list-icon>list</mat-icon>
        <h4 mat-line>Vacinas PDA</h4>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink="/settings/">
        <mat-icon mat-list-icon>settings</mat-icon>
        <h4 mat-line>Configurações</h4>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="logout()">
        <mat-icon mat-list-icon>exit_to_app</mat-icon>
        <h4 mat-line>Logout</h4>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <section
      class="bg"
      [class.fillPrimary]="fillPrimary"
      [class.offline]="!(onlineStatusService.$online | async)"
      (swiperight)="menuOpened = true"
    >
      <mat-toolbar id="actionbar" color="primary">
        <button mat-icon-button (click)="toggleMenu()">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-icon
          routerLink="/"
          class="mat-icon-logo cursor-pointer"
          svgIcon="assets:logo_short_eceos_white"
        ></mat-icon>
        <h1 *ngIf="pageTitle" class="pageTitle">| {{ pageTitle }}</h1>
        <span flex></span>

        <ng-content select="[actions]"></ng-content>
      </mat-toolbar>
      <div class="body"><ng-content></ng-content></div>
      <div class="offline-notification">Você está sem conexão com a internet</div>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
