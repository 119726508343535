import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  @Input() color: ErrorType = ErrorType.Info;

  constructor() {}

  ngOnInit() {}
}
export enum ErrorType {
  Info,
  Warn,
  Fail
}
