import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";
import { EnterpriseSummary } from "@eceos/domain";

export abstract class StockLocationSummary implements DomainEntity{
    public readonly id: string = SgvId.gen();
    constructor(
        public name: string = ''
    ){}
    
    abstract toJson(): any;

    static fromJson(json: any): StockLocationSummary {
        if (!(json || json.type)) return null;

        switch (json.type) {
            case 'enterprise':
                return EnterpriseSummary.fromJson(json)
            case 'subsidiary':
                return SubsidiarySummary.fromJson(json);
            case 'warehouse':
                return WarehouseSummary.fromJson(json);
            default:
                throw new Error('Stock location type not mapped');
        }
    }
}


export class SubsidiarySummary extends StockLocationSummary {

    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = ''
    ) {
        super(name);
    }

    toJson(): any {
        return SgvJson.to.simple(this, { type: 'subsidiary'});
    }

    static fromJson(json: any): SubsidiarySummary {
        return json ? SgvJson.from.simple(json, SubsidiarySummary) : null;
    }
}

export class WarehouseSummary extends StockLocationSummary {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = ''
    ) {
        super(name);
    }

    toJson(): any {
        return SgvJson.to.simple(this, { type: 'warehouse'});
    }

    static fromJson(json: any): WarehouseSummary {
        return json ? SgvJson.from.simple(json, WarehouseSummary) : null;
    }
}