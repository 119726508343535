import { JsonEntity, SgvJson } from '@eceos/arch';
import { Pdv, PdvProfile } from '../../pdvs';
import { Profile } from '../../profiles';
import { ExternalUser, User } from '../../users';

export abstract class Authenticated implements JsonEntity {
  abstract get identifier();

  abstract get name();

  abstract get isExternal();

  abstract toJson();

  static fromJson(json: any) {
    if (!json) return;

    switch (json.type) {
      case 'user':
        return AuthenticatedUser.fromJson(json);
      case 'pdv':
        return AuthenticatedPdv.fromJson(json);
    }
  }
}

export class AuthenticatedUser extends Authenticated {
  constructor(public user: User = null, public profile: Profile = null) {
    super();
  }

  get identifier() {
    return this.user instanceof ExternalUser ? this.user?.email : this.user?.login;
  }

  get name() {
    return this.user?.name;
  }

  get isExternal() {
    return this.user instanceof ExternalUser;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'user',
      user: this.user ? this.user.toJson() : null,
      profile: this.profile ? this.profile.toJson() : null
    });
  }
  static fromJson(json: any): AuthenticatedUser {
    if (!json) {
      return null;
    }

    return SgvJson.from.simple(json, AuthenticatedUser, {
      user: User.fromJson(json.user),
      profile: Profile.fromJson(json.profile)
    });
  }
}

export class AuthenticatedPdv extends Authenticated {
  constructor(public pdv: Pdv = null, public profile: PdvProfile = null) {
    super();
  }

  get identifier() {
    return this.pdv?.identifier;
  }

  get name() {
    return this.pdv?.name;
  }

  get isExternal() {
    return false;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'pdv',
      pdv: this.pdv ? this.pdv.toJson() : null,
      profile: this.profile ? this.profile.toJson() : null
    });
  }
  static fromJson(json: any): AuthenticatedPdv {
    if (!json) {
      return null;
    }

    return SgvJson.from.simple(json, AuthenticatedPdv, {
      pdv: Pdv.fromJson(json.pdv),
      profile: PdvProfile.fromJson(json.profile)
    });
  }
}
