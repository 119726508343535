import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class FiscalUnity implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly acronym = '',
    readonly fullName = ''
  ) {}

  get name(){
    return this.fullName;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): FiscalUnity {
    return SgvJson.from.simple(json, FiscalUnity);
  }
}
