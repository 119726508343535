import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class Partner implements DomainEntity {
  constructor(public readonly id: string = SgvId.gen(), public name = '', public widgetCode = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Partner {
    return json ? SgvJson.from.simple(json, Partner) : null;
  }
}
