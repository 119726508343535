<vpda-layout [loading]="loading">
  <mat-card class="generalDataCard">
    <mat-card-header>
      <section class="status">
        <span class="time" matTooltip="{{ vaccineApplication?.date | date: 'dd/MM/yyyy HH:mm:ss' }}"
          >Início {{ vaccineApplication?.date | amTimeAgo }}</span
        >
      </section>
      <mat-card-title>VACINA Nº {{ vaccineApplication?.number }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <mat-form-field class="contentElem">
        <mat-label>Paciente</mat-label>
        <input
          matInput
          value="{{ vaccineApplication?.patient.name }}, {{
            vaccineApplication?.patient?.birthdate | age
          }}"
          readonly="true"
        />
      </mat-form-field>
      <mat-form-field class="contentElem" *ngIf="vaccineApplication?.isOtherResponsible()">
        <mat-label>Responsável</mat-label>
        <input
          matInput
          value="{{ vaccineApplication?.otherResponsible?.client?.name }}"
          readonly="true"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="itemsCard">
    <mat-card-header>
      <mat-card-title>ITENS</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <mat-table class="allItems" [dataSource]="items">
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>Vacina</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.type.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="vaccine">
          <mat-header-cell *matHeaderCellDef>Produto</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.operatable.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="doctor">
          <mat-header-cell *matHeaderCellDef>Médico</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.doctor ? element.doctor.name : 'SEM INDICACAO MEDICA' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="barcode">
          <mat-header-cell *matHeaderCellDef>Conferência</mat-header-cell>
          <mat-cell *matCellDef="let element" class="barcodes">
            <mat-form-field [class.validOne]="element.isConferenceValid()">
              <input
                appBarcode
                name="conference"
                #conference="ngModel"
                matInput
                [(ngModel)]="element.barcodeConference"
                [errorStateMatcher]="matcher"
                [autofocus]="isFirstVaccine(element)"
                required
              />
            </mat-form-field>

            <ng-container *ngIf="conference.dirty || conference.touched">
              <div *ngIf="!element.hasConference()" class="withValidationError">
                Campo <strong>obrigatório</strong>.
              </div>
              <div
                *ngIf="element.hasConference() && element.isConferenceInvalid()"
                class="withValidationError"
              >
                Conferência <strong>inválida</strong>.
              </div>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="bodySite">
          <mat-header-cell *matHeaderCellDef>Local do Corpo</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field>
              <mat-select placeholder="Selecione" [(ngModel)]="element.bodySite" name="bodySite">
                <mat-option *ngFor="let bodySite of bodySites" [value]="bodySite">
                  {{ bodySite.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row #rowId *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-fab
        class="floatDefault"
        *ngIf="areAllItemsValid()"
        (click)="finishApplying()"
        (touch)="finishApplying()"
        [disabled]="loading"
      >
        <mat-icon>done</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</vpda-layout>
