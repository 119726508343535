
export class ExpirationType {
    static NOT_PERISHABLE = new ExpirationType('NOT_PERISHABLE');
    static PERISHABLE = new ExpirationType('PERISHABLE')

    constructor(readonly type: string) {}

    static values(): ExpirationType[]{
        return [ExpirationType.PERISHABLE, ExpirationType.NOT_PERISHABLE];
    }

    public static get(type: string) {
        const expirationType = ExpirationType.values().find(t => t.type === type);
        if(!expirationType) {
            const err = new Error (`${type} ExpirationType is not mapped!`);
            console.error(err)
            throw err;
        }
        return expirationType;
    }
}