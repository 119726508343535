import { Injectable, OnDestroy } from '@angular/core';
import { Action, Functionality, FunctionalityValidator } from '@eceos/arch';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { CurrentInfoService } from '../../current';
import { Authenticated, AuthenticatedUser } from '../authenticated';
import { Permission } from './permission';

@Injectable()
export class PermissionService extends FunctionalityValidator implements OnDestroy {
  private permissions: Map<Functionality, Permission> = new Map();

  private permissionsChange = new Subject<Map<Functionality, Permission>>();

  public readonly $permissions = this.permissionsChange.pipe(untilDestroyed(this), shareReplay(1));

  constructor(public current: CurrentInfoService) {
    super();
    this.current.$value
      .pipe(
        untilDestroyed(this),
        debounceTime(100),
        map((v) => {
          const authenticated: Authenticated = v.authenticated;
          if (authenticated instanceof AuthenticatedUser) {
            return authenticated.profile.permissions;
          } else {
            return [];
          }
        })
      )
      .subscribe((v) => {
        this.loadPermissions(v);
      });
  }

  get $change() {
    return this.permissionsChange.asObservable();
  }

  getPermissionOf(feature: Functionality): Permission {
    return feature ? this.permissions.get(feature) : null;
  }

  canAccess(feature: Functionality, ...actions: Action[]): boolean {
    const perm = this.getPermissionOf(feature);
    return perm && perm.canAccess && perm.canDo(...actions);
  }

  ngOnDestroy(): void {}

  private loadPermissions(newPerms: Permission[]) {
    this.permissions.clear();
    for (const perm of newPerms) {
      if (perm) {
        this.permissions.set(perm.feature, perm);
      }
    }
    this.permissionsChange.next(this.permissions);
  }
}
