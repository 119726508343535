import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class PaymentCovenantSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly charged = false,
    public readonly providesRecord = false,
    public readonly boundToDiscount = false
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): PaymentCovenantSummary {
    return json ? SgvJson.from.simple(json, PaymentCovenantSummary) : null;
  }
}
