import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { FiscalUnity } from '../../fiscal';

export class OperatableUnityLegacy implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly acronym = '',
    readonly fullName = '',
    readonly isHour = false,
    readonly isWeightable = false,
    readonly fiscalUnity: FiscalUnity = null
  ) { }

  toJson() {
    return SgvJson.to.simple(this, {
      fiscalUnity: this.fiscalUnity ? this.fiscalUnity.toJson() : null
    });
  }

  static fromJson(json: any): OperatableUnityLegacy {
    return SgvJson.from.simple(json, OperatableUnityLegacy, {
      fiscalUnity: FiscalUnity.fromJson(json.fiscalUnity)
    });
  }
}