import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(value: Date | moment.Moment, args: string[]): any {
    if (!value) {
      return value;
    }

    let result = moment().diff(value, 'years');

    if (result > 0) {
      return result + ' ' + this.getYearsLabel(result);
    }

    result = moment().diff(value, 'months');

    return result + ' ' + this.getMonthsLabel(result);
  }

  getYearsLabel(result: number): string {
    if (result > 1) {
      return 'Anos';
    }
    return 'Ano';
  }

  getMonthsLabel(result: number): string {
    if (result > 1) {
      return 'Meses';
    }
    return 'Mês';
  }
}
