import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class Periodicity implements JsonEntity {
  abstract toJson(): any;

  abstract get recurrences(): number;
  abstract set recurrences(recurrences: number);
  static fromJson(data: any): Periodicity {
    if (data) {
      switch (data.type) {
        case 'dayly':
          return SgvJson.from.simple(data, DailyPeriodicity);
        case 'weekly':
          return SgvJson.from.simple(data, WeeklyPeriodicity);
        case 'monthly':
          return SgvJson.from.simple(data, MonthlyPeriodicity);
        case 'yearly':
          return SgvJson.from.simple(data, YearlyPeriodicity);
      }
    }
    return void 0;
  }
}

export class DailyPeriodicity extends Periodicity {
  public recurrences = 1;
  toJson(): any {
    return SgvJson.to.simple(this, { type: 'daily' });
  }
}

export class MonthlyPeriodicity extends Periodicity {
  public recurrences = 1;
  toJson(): any {
    return SgvJson.to.simple(this, { type: 'monthly' });
  }
}

export class WeeklyPeriodicity extends Periodicity {
  public recurrences = 1;
  toJson(): any {
    return SgvJson.to.simple(this, { type: 'weekly' });
  }
}

export class YearlyPeriodicity extends Periodicity {
  public recurrences = 1;
  toJson(): any {
    return SgvJson.to.simple(this, { type: 'yearly' });
  }
}
