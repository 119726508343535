import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService, OnlineStatusService } from '@eceos/arch';
import { CurrentUserService } from '@eceos/domain';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
  selector: 'vpda-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit, OnDestroy {
  @Input() loading = false;

  @Input() fillPrimary = false;

  @Input() pageTitle: string = null;

  @Input() showSearch = false;

  menuOpened = false;

  constructor(
    public loader: LoadingBarService,
    public onlineStatusService: OnlineStatusService,
    public currentUser: CurrentUserService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  logout() {
    this.authService.publishLogout();
    location.reload();
  }

  ngOnDestroy(): void {}
}
