import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { PatientSummary } from './../patients/patient-summary';

export class VaccineApplicationSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly number = '',
    public readonly patient: PatientSummary = null,
    public readonly date: Date = null,
    public readonly vaccinesRequested = ''
  ) {}
  toJson(): any {
    return SgvJson.to.simple(this, {
      patient: this.patient.toJson()
    });
  }
  static fromJson(json: any): VaccineApplicationSummary {
    return SgvJson.from.simple(json, VaccineApplicationSummary, {
      patient: PatientSummary.fromJson(json.patient)
    });
  }
}
