import { JsonEntity, SgvJson, SgvId } from '@eceos/arch';

export class FinancialDocument implements JsonEntity {
  public readonly id = SgvId.gen();
  public name = '';
  public allowChange = false;
  public forIncome = true;
  public forExpense = true;

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): FinancialDocument {
    return SgvJson.from.simple(json, FinancialDocument);
  }
}
