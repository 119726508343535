import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { OperatableCategorySummary } from '@eceos/domain';
import { Stock } from './stock';
import { OperatableUnityLegacy } from './unities';

export class Operatable implements DomainEntity {
  public readonly id = SgvId.gen();
  public barcode = '';
  public name = '';
  public defaultSellValue = 0.0;
  public defaultUnity: OperatableUnityLegacy = null;
  public stock?: Stock;
  public mainCategory?: OperatableCategorySummary;


  toJson(): any {
    return SgvJson.to.simple(this, {
      defaultUnity: this.defaultUnity? this.defaultUnity.toJson() : null,
      stock: this.stock? this.stock.toJson() : null,
      mainCategory: this.mainCategory? this.mainCategory.toJson() : null
    });
  }

  static fromJson(json: any): Operatable {
    return SgvJson.from.simple(json, Operatable, {
      defaultUnity: OperatableUnityLegacy.fromJson(json.defaultUnity),
      stock: Stock.fromJson(json.stock),
      mainCategory: OperatableCategorySummary.fromJson(json.mainCategory)
    });
  }

  get isStockAvailable(){
    if (this.stock){
      return this.stock.available > 0;
    }
    return true;
  }
  
}
