import { JsonEntity, SgvJson } from '@eceos/arch';
import { ClientSummary } from '../../clients';

export abstract class ResponsibleByPatient implements JsonEntity {
  abstract get client(): ClientSummary;
  abstract toJson(): any;
  abstract isValid(): boolean;

  static fromJson(data: any): ResponsibleByPatient {
    if (data) {
      switch (data.type) {
        case 'self':
          return SelfResponsible.fromJson(data);
        case 'other':
          return OtherResponsible.fromJson(data);
      }
    }
    return void 0;
  }
}

export class OtherResponsible extends ResponsibleByPatient {
  constructor(public client: ClientSummary = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'other',
      client: this.client.toJson()
    });
  }
  isValid() {
    return Boolean(this.client);
  }

  static fromJson(data: any): OtherResponsible {
    return SgvJson.from.simple(data, OtherResponsible, {
      client: ClientSummary.fromJson(data.client)
    });
  }
}

export class SelfResponsible extends ResponsibleByPatient {
  constructor(public client: ClientSummary = null) {
    super();
  }
  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'self',
      client: SgvJson.to.optional(this.client)
    });
  }

  isValid() {
    return true;
  }

  static fromJson(data: any): SelfResponsible {
    return SgvJson.from.simple(data, SelfResponsible, {
      client: ClientSummary.fromJson(data.client)
    });
  }
}
