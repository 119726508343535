import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsModule } from './errors';
import { LoginModule } from './login';

@NgModule({
  imports: [CommonModule, LoginModule, ErrorsModule],
  exports: [LoginModule, ErrorsModule]
})
export class CommonPagesModule {}
