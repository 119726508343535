import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  AUTO_CATCH_CONFIG,
  HttpErrorHandler,
  HttpErrorInterceptor,
  InactiveEnterpriseErrorHandler,
  InterceptorConfig
} from '@eceos/arch';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutes,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    CoreModule,
    FeaturesModule,
    SharedModule,
    HammerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: AUTO_CATCH_CONFIG, useValue: InterceptorConfig.NO_INTERCEPT },
    { provide: HttpErrorHandler, useClass: InactiveEnterpriseErrorHandler, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
