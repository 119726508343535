export class SalesOrderState {
  static PENDENTE = new SalesOrderState('PENDENTE', 'Pendente');
  static NEGADO = new SalesOrderState('NEGADO', 'Negado');
  static APROVADO = new SalesOrderState('APROVADO', 'Aprovado');
  static CANCELADO = new SalesOrderState('CANCELADO', 'Cancelado');
  static FINALIZADO = new SalesOrderState('FINALIZADO', 'Finalizado');

  private constructor(readonly name: string, readonly label: string) {}

  static get(name: string): SalesOrderState {
    const state = SalesOrderState.values().find(v => v.name === name);
    if (!state) {
      const err = new Error(`${name} SalesOrderState is not mapped!`);
      console.error(err);
      throw err;
    }
    return state;
  }

  static getOrNull(name: string): SalesOrderState {
    return SalesOrderState.values().find(v => v.name === name);
  }

  static values(): SalesOrderState[] {
    return [
      SalesOrderState.PENDENTE,
      SalesOrderState.NEGADO,
      SalesOrderState.APROVADO,
      SalesOrderState.CANCELADO,
      SalesOrderState.FINALIZADO
    ];
  }
}
