import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponse, HttpErrorHandler } from './handlers/http-error-handler';
import { InterceptorConfig } from './interceptors.config';

export const INTERCEPT_ERROR_RESPONSE_HEADER = 'InterceptErrorResponse';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  private handlers: HttpErrorHandler[] = [];

  constructor(@Inject(HttpErrorHandler) @Optional() handlers: HttpErrorHandler[]) {
    this.handlers = handlers
      ? handlers.sort((obj, otherObj) => obj.priority - otherObj.priority)
      : [];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.prepareRequest(req)).pipe(
      catchError(async (response: HttpErrorResponse) => {
        if (response.error instanceof Blob && response.error.type === "application/json") {
          response = await this.buildBlobHttpErrorResponse(response);
        }
        if (this.canIntercept(req)) {
          for (const handler of this.handlers) {
            const error = ErrorResponse.from(response);
            if (handler.matches(error)) {
              handler.handle(error);
            }
          }
        }
        throw response;
      })
    );

  }

  private async buildBlobHttpErrorResponse(response: HttpErrorResponse): Promise<HttpErrorResponse> {
    const errmsg: ErrorResponse = JSON.parse(await this.readFileAsText(response.error));
    return new HttpErrorResponse({
      error: errmsg,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      url: response.url
    });
  }

  private readFileAsText(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = (e: Event) => resolve((<any>e.target).result)
      reader.readAsText(blob);
    })
  }

  private prepareRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (request.headers.has(INTERCEPT_ERROR_RESPONSE_HEADER)) {
      return request.clone({
        headers: request.headers.delete(INTERCEPT_ERROR_RESPONSE_HEADER)
      });
    }
    return request;
  }

  private canIntercept(request: HttpRequest<any>): boolean {
    return (
      request.headers.has(INTERCEPT_ERROR_RESPONSE_HEADER) &&
      request.headers.get(INTERCEPT_ERROR_RESPONSE_HEADER) === InterceptorConfig.INTERCEPT.value
    );
  }
}
