import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiPath } from '../api/api.service';
import { PageData, UrlSearchParamsBuilder } from '../repositories/search-options';

export class CubeDimension {
  constructor(readonly id: string) {}

  asFilter(operator: QueryFilterOp, value: string) {
    return new QueryFilter(this, operator, value);
  }
}

export class CubeMeasure {
  constructor(readonly id: string) {}

  asFilter(operator: QueryFilterOp, value: string) {
    return new QueryFilter(this, operator, value);
  }

  asValue(operator: QueryValueOp) {
    return new QueryValue(this, operator);
  }
}

export interface CubeQuery {
  groups: CubeDimension[];
  filters?: QueryFilter[];
  values?: QueryValue[];
  sorts?: string[];
  page?: PageData;
}

export class QueryFilter {
  constructor(
    readonly filter: CubeDimension | CubeMeasure,
    readonly operator: QueryFilterOp,
    readonly value: string 
  ) {}

  asFilterString() {
    return `${this.filter.id}:${this.operator}:${this.value}`;
  }
}

export enum QueryFilterOp {
  EQ = 'EQ',
  NE = 'NE',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE'
}

export class QueryValue {
  constructor(
    readonly measure: CubeMeasure,
    readonly operator: QueryValueOp,
    readonly label?: string
  ) {}

  asValueString() {
    const base = `${this.operator}:${this.measure.id}`;
    return this.label ? `${this.label}:${base}` : base;
  }
}

export enum QueryValueOp {
  SUM = 'SUM',
  AVG = 'AVG'
}

export interface CubeQueryResult {
  groups: any;
  values: any;
}

export class AnalyticsService {
  constructor(private cubeRoot: ApiPath) {}

  fetch(query: CubeQuery): Observable<CubeQueryResult[]> {
    return this.cubeRoot.getJson({
      params: this.parseQuery(query)
    });
  }

  protected parseQuery(query: CubeQuery): HttpParams {
    let params = new HttpParams().append('groups', query.groups.map(g => g.id).join(','));
    if (query.filters) {
      params = params.append('filters', query.filters.map(f => f.asFilterString()).join(','));
    }
    if (query.values) {
      params = params.append('values', query.values.map(v => v.asValueString()).join(','));
    }
    if (query.sorts) {
      params = params.append('sorts', query.sorts.join(','));
    }
    if (query.page) {
      params = new UrlSearchParamsBuilder(params).page(query.page).build();
    }
    return params;
  }
}
