import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnknownErrorComponent } from './unknown-error/unknown-error.component';
import { SharedThirdPartyModule } from '@eceos/shared-third-party';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, SharedThirdPartyModule, RouterModule],
  declarations: [ErrorPageComponent, PageNotFoundComponent, UnknownErrorComponent],
  exports: [ErrorPageComponent, PageNotFoundComponent, UnknownErrorComponent]
})
export class ErrorsModule {}
