import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputNumberConfig, INPUT_NUMBER_CONFIG } from './input-number.config';
import { InputNumberDirective } from './input-number.directive';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [InputNumberDirective],
  exports: [InputNumberDirective]
})
export class InputNumberModule {
  static forRoot(config: InputNumberConfig): ModuleWithProviders<InputNumberModule> {
    return {
      ngModule: InputNumberModule,
      providers: [
        {
          provide: INPUT_NUMBER_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
