import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class ShippingModality implements DomainEntity {

  public static BY_ISSUER = new ShippingModality('BY_ISSUER', 'Contratação do frete por conta do remetente (CIF)', '0');
  public static BY_RECEIVER_SENDER = new ShippingModality('BY_RECEIVER_SENDER', 'Contratação do frete por conta do destinatário (FOB)', '1');
  public static BY_THIRD = new ShippingModality('BY_THIRD', 'Contratação do frete por conta de terceiros', '2');
  public static OWN_BY_SENDER = new ShippingModality('OWN_BY_SENDER', 'Transporte próprio por conta do remetente', '3');
  public static OWN_BY_RECEIVER = new ShippingModality('OWN_BY_RECEIVER', 'Transporte próprio por conta do destinatário', '4');
  public static WITHOUT_TRANSPORT = new ShippingModality('WITHOUT_TRANSPORT', 'Sem ocorrência de transporte', '9');

  constructor(
    readonly id = SgvId.gen(),
    readonly name = '',
    readonly number = ''
  ) {}

  get nameWithNumber(): string {
    return `${this.number} - ${this.name}`;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ShippingModality {
    return json ? SgvJson.from.simple(json, ShippingModality) : null;
  }

  public static values(): ShippingModality[] {
    return [
      ShippingModality.BY_ISSUER,
      ShippingModality.BY_RECEIVER_SENDER,
      ShippingModality.BY_THIRD,
      ShippingModality.OWN_BY_SENDER,
      ShippingModality.OWN_BY_RECEIVER,
      ShippingModality.WITHOUT_TRANSPORT
    ];
  }

}
