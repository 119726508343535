import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class PaymentCovenantData implements JsonEntity {
  abstract toJson(): any;

  static fromJson(data: any): PaymentCovenantData {
    if (data) {
      switch (data.type) {
        case 'default':
          return PaymentCovenantDefaultData.fromJson(data);
        case 'card':
          return PaymentCovenantCardData.fromJson(data);
      }
    }
    return void 0;
  }
}

export class PaymentCovenantDefaultData extends PaymentCovenantData {
  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'default'
    });
  }

  static fromJson(data: any): PaymentCovenantDefaultData {
    return SgvJson.from.simple(data, PaymentCovenantDefaultData);
  }
}

export class PaymentCovenantCardData extends PaymentCovenantData {
  constructor(public cardNumber = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'card'
    });
  }

  isValid() {
    return Boolean(this.cardNumber);
  }

  static fromJson(data: any): PaymentCovenantCardData {
    return SgvJson.from.simple(data, PaymentCovenantCardData);
  }
}
