export const AUTO_CATCH_CONFIG = 'autoCatchConfig';
export class InterceptorConfig {
  static INTERCEPT = new InterceptorConfig('intercept');
  static NO_INTERCEPT = new InterceptorConfig('noIntercept');
  static AUTO = new InterceptorConfig('auto');
  private constructor(readonly value: string) {}

  static compareAndGet(config: InterceptorConfig, otherConfig: InterceptorConfig) {
    return config === InterceptorConfig.AUTO ? otherConfig : config || otherConfig;
  }
}
