import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieSnackBarComponent } from './cookie-snackbar/cookie-snackbar.component';
import { COOKIE_NAME } from './cookie.config';

enum CookieSettings {
  CONSENT = "consent",
  REQUEST_ID = "request_id",
  DATE = "date"
}

@Injectable({ providedIn: 'root' })
export class CookieService {
  
  constructor(@Inject(COOKIE_NAME) private cookieName: string, private snackBar: MatSnackBar) {}

  setLocalStorageCookieConsent() {
    const cookieStorage = {
      [CookieSettings.CONSENT]: "true",
      [CookieSettings.REQUEST_ID]: `${Math.random().toString(36).substring(7)}`,
      [CookieSettings.DATE]: `${new Date().toLocaleDateString()}`
    }
    localStorage.setItem(this.cookieName, JSON.stringify(cookieStorage));
  }

  getLocalStorageCookieConsent() {
    return localStorage.getItem(this.cookieName);
  }

  checkCookieConsent() {
    if (!this.getLocalStorageCookieConsent()) {
      this.snackBar
        .openFromComponent(CookieSnackBarComponent, { panelClass: ['thin-snackbar'] })
        .afterDismissed()
        .subscribe(() => this.setLocalStorageCookieConsent());
    }
  }
}
