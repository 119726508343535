import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class HwHub implements DomainEntity {
  readonly id: string = SgvId.gen();
  readonly name: string = '';

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): HwHub {
    return SgvJson.from.simple(json, HwHub);
  }
}
