import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomDatePickerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDatePickerComponent implements OnInit, ControlValueAccessor, Validator {
  private readonly defaultConfig: CustomDatePickerConfig = {
    type: 'datetime',
    format: 'dd/MM/yyyy HH:mm',
    openOnFocus: false,
    mode: 'portrait',
    touchUi: true,
    startView: 'month'
  };

  private _config: Partial<CustomDatePickerConfig> = this.defaultConfig;

  private _selectedOption: Options = Options.NOW;

  private _selectedDate: Date = null;

  private registerOnChangeSubscription: Subscription = null;

  valueChange = new EventEmitter<Date>();

  onTouchedListener = new EventEmitter<any>();

  onValidatorListener = new EventEmitter<any>();

  readonly options = Options.values();

  @Input() required = true;

  @Input() disabled = false;

  @ViewChild('datetimePicker') picker: MatDatepicker<Date>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  get isCustomOptionSelected(): boolean {
    return this._selectedOption === Options.CUSTOM;
  }

  get selectedOption(): Options {
    return this._selectedOption;
  }

  set selectedOption(option: Options) {
    this._selectedOption = option;
    if (this.isCustomOptionSelected) {
      this.selectedDate = new Date();
      this.picker.open();
    } else {
      this.selectedDate = null;
    }
  }

  get selectedDate(): Date {
    return this._selectedDate;
  }

  set selectedDate(date: Date) {
    if (this._selectedDate !== date) {
      this._selectedDate = date;
      this.valueChange.emit(date);
      this.changeDetector.markForCheck();
    }
  }
  @Input()
  set config(config: Partial<CustomDatePickerConfig>) {
    this._config = (<any>Object).assign({}, this.defaultConfig, config);
    this.changeDetector.markForCheck();
  }

  get config(): Partial<CustomDatePickerConfig> {
    return this._config;
  }

  writeValue(obj: any): void {
    this.selectedDate = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener.emit(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control?: AbstractControl): ValidationErrors {
    return this.validationErrors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorListener.emit(fn);
  }

  get validationErrors(): ValidationErrors {
    const errors = {};

    if (!this.selectedDate && this.isCustomOptionSelected) {
      errors['invalid'] = true;
    }

    if (!this.selectedOption && this.required) {
      errors['required'] = true;
    }

    return Object.entries(errors).length === 0 ? null : errors;
  }

}

class Options {
  static NOW = new Options('Data atual');
  static CUSTOM = new Options('Data Personalizada');

  constructor(readonly label: string) {}

  static values(): Options[] {
    return [Options.NOW, Options.CUSTOM];
  }
}

export interface CustomDatePickerConfig {
  type: 'date' | 'time' | 'month' | 'datetime';
  min?: Date;
  max?: Date;
  format: string;
  openOnFocus: boolean;
  mode: 'auto' | 'portrait' | 'landscape';
  touchUi: boolean;
  startView: 'clock' | 'month' | 'year';
  dateFilter?: (value: Date | null) => boolean;
}
