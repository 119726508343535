import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { SharedThirdPartyModule } from '@eceos/shared-third-party';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, SharedThirdPartyModule, RouterModule],
  declarations: [LoginComponent],
  exports: [LoginComponent]
})
export class LoginModule {}
