import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BodySitesRepository,
  BodySiteSummary,
  VaccineApplication,
  VaccineApplicationItem,
  VaccineApplicationsRepository,
  VaccineApplicationState
} from '@eceos/domain';
import { switchMap } from 'rxjs/operators';
import { InterceptorConfig } from '@eceos/arch';

@Component({
  selector: 'vpda-vaccine-application',
  templateUrl: './vaccine-application.component.html',
  styleUrls: ['./vaccine-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VaccineApplicationComponent implements OnInit {
  loading = false;

  vaccineApplication: VaccineApplication;

  items = new MatTableDataSource();

  displayedColumns = ['type', 'vaccine', 'doctor', 'barcode', 'bodySite'];

  bodySites: BodySiteSummary[];

  matcher = new MyErrorStateMatcher();

  constructor(
    private route: ActivatedRoute,
    private repository: VaccineApplicationsRepository,
    private bodySitesRepository: BodySitesRepository,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(switchMap(params => this.repository.find(params.get('id')))).subscribe(
      (va: VaccineApplication) => {
        this.vaccineApplication = va;
        this.items.data = va.items;

        if (
          VaccineApplicationState[VaccineApplicationState.PENDING_APPLICATION] !==
          this.vaccineApplication.state.toString()
        ) {
          this.showMessage(
            `Aplicação de Vacina Nº ${this.vaccineApplication.number} não está pendente.`
          );
          this.router.navigate(['/vaccines']);
        }
      },
      e => {
        this.vaccineApplication = null;
        this.items.data = null;
        console.error(e);
        this.router.navigate(['/vaccines']);
      }
    );

    this.bodySitesRepository
      .list()
      .subscribe(result => (this.bodySites = result), e => console.log(e));
  }

  checkBarcodeValidity(item: VaccineApplicationItem, value: string) {
    item.barcodeConference = value;
    if (item.isConferenceValid()) {
      this.showMessage(`Código de Barras ${item.barcodeConference} é válido.`);
    } else {
      this.showMessage(`Código de Barras ${item.barcodeConference} é inválido.`);
    }
  }

  finishApplying() {
    if (!this.loading) {
      this.loading = true;

      if (this.vaccineApplication.items.filter(it => !it.isConferenceValid).length > 0) {
        this.showMessage('Ainda há itens pendentes de vacinação.');
        this.loading = false;
        return;
      }

      this.repository
        .finalizeApplication(this.vaccineApplication, { autoCatch: InterceptorConfig.INTERCEPT })
        .subscribe(
          result => {
            this.showMessage(`Vacinação Nº ${this.vaccineApplication.number} Finalizada.`);
            this.router.navigate(['/vaccines/applied', this.vaccineApplication.id]);
            this.loading = false;
          },
          e => (this.loading = false),
          () => (this.loading = false)
        );
    }
  }
  areAllItemsValid(): boolean {
    const totalOfItems = this.items.data.length;
    const validItems = this.items.data.filter((it: VaccineApplicationItem) =>
      it.isConferenceValid()
    ).length;

    return totalOfItems === validItems;
  }
  isFirstVaccine(item: VaccineApplicationItem): boolean {
    if (this.items.data.length > 0) {
      return this.items.data[0] === item;
    }
    return false;
  }

  private showMessage(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
