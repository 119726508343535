import { InjectionToken } from '@angular/core';

export interface InputNumberConfig {
  align: string;
  allowNegative: boolean;
  allowZero: boolean;
  decimalSeparator: string;
  thousandSeparator: string;
  prefix: string;
  suffix: string;
  precision: number;
  nullable: boolean;
  maxValue?: number;
  minValue?: number;
}

export let INPUT_NUMBER_CONFIG = new InjectionToken<InputNumberConfig>('input-number.config');
