import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LoginModule } from './login/login.module';
import { SettingsModule } from './settings/settings.module';
import { VaccinesModule } from './vaccines/vaccines.module';

@NgModule({
  imports: [CommonModule, SharedModule, LoginModule, SettingsModule, VaccinesModule],
  declarations: []
})
export class FeaturesModule {}
