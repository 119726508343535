<vpda-layout [loading]="loading">
  <mat-card class="summaryCard">
    <mat-card-header>
      <mat-card-title>VACINA Nº {{ vaccineApplication.number }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <mat-form-field class="contentElem">
        <mat-label>Paciente</mat-label>
        <input
          matInput
          value="{{ vaccineApplication.patient.name }}, {{
            vaccineApplication.patient.birthdate | age
          }}"
          readonly="true"
        />
      </mat-form-field>
      <mat-form-field class="contentElem" *ngIf="vaccineApplication.isOtherResponsible()">
        <mat-label>Responsável</mat-label>
        <input
          matInput
          value="{{ vaccineApplication.otherResponsible.client.name }}"
          readonly="true"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="itemsCard">
    <mat-card-header>
      <mat-card-title>ITENS</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <mat-table class="table" #table [dataSource]="items">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef [ngClass]="'selectColumn'">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" [ngClass]="'selectColumn'">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef [ngClass]="'vaccineColumn'">Vacina</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'vaccineColumn'">{{
            element.type.name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="vaccine">
          <mat-header-cell *matHeaderCellDef [ngClass]="'productColumn'">Produto</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'productColumn'">{{
            element.operatable.name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="bodySite">
          <mat-header-cell *matHeaderCellDef [ngClass]="'bodySiteColumn'"
            >Local do Corpo</mat-header-cell
          >
          <mat-cell *matCellDef="let element" [ngClass]="'bodySiteColumn'">{{
            element.bodySite ? element.bodySite.name : 'NÃO INFORMADO'
          }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row #rowId *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-fab
        class="floatDefault"
        (click)="printBarcodes()"
        *ngIf="selection.selected.length > 0"
      >
        <mat-icon>print</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</vpda-layout>
