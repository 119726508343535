import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class Stock implements DomainEntity {
    constructor(
        readonly id = SgvId.gen(),
        readonly total = null,
        readonly available = null,
        readonly reserved = null
    ) { }

    toJson() {
        return SgvJson.to.simple(this)
    }

    static fromJson(json: any): Stock {
        return SgvJson.from.simple(json, Stock)
    }
}