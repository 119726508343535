import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, AUTH_HEADER_NAME, AuthManagerService } from './../auth/auth.service';
import { InterceptorConfig, AUTO_CATCH_CONFIG } from '../interceptors/interceptors.config';
import { INTERCEPT_ERROR_RESPONSE_HEADER } from '../interceptors/http-error-interceptor';

export const CONTENT_TYPE_HEADER_NAME = 'content-type';
export const API_ROOT_URL = 'apiRootUrl';
export const SGV_ROOT_URL = 'sgvRootUrl';
export const ANALYTCS_API_ROOT_URL = 'analyticsApiRootUrl';
export const MANAGER_API_ROOT_URL = 'managerApiRootUrl';

export interface ApiRequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
  withCredentials?: boolean;
  metadata?: RequestMetadata;
  supressDefaultAuth?: boolean;
}

export interface RequestMetadata {
  autoCatch?: InterceptorConfig;
}

export class RequestMetadataUtils {
  static merge(metadata: RequestMetadata, otherMetadata: RequestMetadata): RequestMetadata {
    return Object.assign(otherMetadata, metadata, {
      autoCatch: InterceptorConfig.compareAndGet(metadata.autoCatch, otherMetadata.autoCatch)
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
    @Inject(API_ROOT_URL) private apiRoot: string,
    @Inject(ANALYTCS_API_ROOT_URL) @Optional() private analyticsRoot: string,
    @Inject(MANAGER_API_ROOT_URL) @Optional() private managerRoot: string,
    @Inject(AUTO_CATCH_CONFIG) private interceptorConfig: InterceptorConfig,
    private auth: AuthService,
    private authManager: AuthManagerService
  ) {}

  get root(): ApiPath {
    return new ApiPath(this.http, this.apiRoot, this.auth, this.interceptorConfig);
  }

  get analytics(): ApiPath {
    return new ApiPath(
      this.http,
      this.analyticsRoot || this.apiRoot,
      this.auth,
      this.interceptorConfig
    );
  }

  get manager(): ApiPath {
    return new ApiPath(
      this.http,
      this.managerRoot || this.apiRoot,
      this.authManager,
      this.interceptorConfig
    );
  }
}

export class ApiPath {
  private paths: string[] = [];

  constructor(
    private http: HttpClient,
    private root: string,
    private auth: AuthService,
    private interceptorConfig: InterceptorConfig,
    paths: string[] = []
  ) {
    if (this.root.endsWith('/')) {
      this.root = this.root.slice(0, -1);
    }
    for (const path of paths) {
      for (const p of path.split('/')) {
        const parsed = p.replace('/', '');
        if (parsed) {
          this.paths.push(parsed);
        }
      }
    }
  }

  get url(): string {
    const paths = this.paths.map((p) => `/${p}`).reduce((p1, p2) => `${p1}${p2}`, '');
    return `${this.root}${paths}`;
  }

  path(...paths: string[]): ApiPath {
    return new ApiPath(
      this.http,
      this.root,
      this.auth,
      this.interceptorConfig,
      this.paths.concat(paths)
    );
  }

  run<T>(req: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.http.request(req);
  }

  getBlob(options: ApiRequestOptions = {}): Observable<Blob> {
    return this.http.get(this.url, {
      headers: this.defaults(options),
      params: options.params,
      responseType: 'blob',
      withCredentials: options.withCredentials
    });
  }

  getJson<T>(options: ApiRequestOptions = {}): Observable<T> {
    return this.http.get<T>(this.url, {
      observe: 'body',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  getResponse<T>(options: ApiRequestOptions = {}): Observable<HttpResponse<T>> {
    return this.http.get<T>(this.url, {
      observe: 'response',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  getText(options: ApiRequestOptions = {}): Observable<string> {
    return this.http.get(this.url, {
      observe: 'body',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'text',
      withCredentials: options.withCredentials
    });
  }

  post(options: ApiRequestOptions = {}): Observable<string> {
    return this.http.post(this.url, null, {
      observe: 'body',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'text',
      withCredentials: options.withCredentials
    });
  }

  postText<T>(body: string, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.url, body, {
      observe: 'body',
      headers: this.defaultsText(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  postBlob<T>(body: Blob, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.url, body, {
      observe: 'body',
      headers: this.defaultsText(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  postForm<T>(body: URLSearchParams, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.url, body.toString(), {
      observe: 'body',
      headers: this.defaultsForm(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  postMultipartForm<T>(body: FormData, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.url, body, {
      observe: 'body',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  postJson<T>(body: any, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.url, body, {
      observe: 'body',
      headers: this.defaultsJson(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  putJson<T>(body: any, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.put<T>(this.url, body, {
      observe: 'body',
      headers: this.defaultsJson(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  putMultipartForm<T>(body: FormData, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.put<T>(this.url, body, {
      observe: 'body',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  patchJson<T>(body: any, options: ApiRequestOptions = {}): Observable<T> {
    return this.http.patch<T>(this.url, body, {
      observe: 'body',
      headers: this.defaultsJson(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  delete<T>(options: ApiRequestOptions = {}): Observable<T> {
    return this.http.delete<T>(this.url, {
      observe: 'body',
      headers: this.defaults(options),
      params: options.params,
      responseType: 'json',
      withCredentials: options.withCredentials
    });
  }

  private defaultsText(options: ApiRequestOptions): HttpHeaders {
    let headers = this.defaults(options);
    if (!headers.has(CONTENT_TYPE_HEADER_NAME)) {
      headers = headers.set(CONTENT_TYPE_HEADER_NAME, 'text/plain');
    }
    return headers;
  }

  private defaultsJson(options: ApiRequestOptions): HttpHeaders {
    let headers = this.defaults(options);
    if (!headers.has(CONTENT_TYPE_HEADER_NAME)) {
      headers = headers.set(CONTENT_TYPE_HEADER_NAME, 'application/json');
    }
    return headers;
  }

  private defaultsForm(options: ApiRequestOptions): HttpHeaders {
    let headers = this.defaults(options);
    if (!headers.has(CONTENT_TYPE_HEADER_NAME)) {
      headers = headers.set(CONTENT_TYPE_HEADER_NAME, 'application/x-www-form-urlencoded');
    }
    return headers;
  }

  private defaults(options: ApiRequestOptions): HttpHeaders {
    let headers = options.headers || new HttpHeaders();
    if (!headers.has(AUTH_HEADER_NAME) && !options.supressDefaultAuth) {
      headers = headers.set(AUTH_HEADER_NAME, this.auth.headerValue);
    }

    let autoCatch = this.interceptorConfig;
    if (options.metadata && options.metadata.autoCatch) {
      autoCatch = InterceptorConfig.compareAndGet(
        options.metadata.autoCatch,
        this.interceptorConfig
      );
    }

    headers = headers.set(INTERCEPT_ERROR_RESPONSE_HEADER, autoCatch.value);

    return headers;
  }
}
