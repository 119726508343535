import { JsonEntity, SgvJson } from '@eceos/arch';
import { Periodicity } from '../../core/periodicities/periodicity';

export class ParcelSimulator implements JsonEntity {
  constructor(
    readonly parceledValue: number,
    readonly numberOfInstallments: number,
    readonly firstExpiration: Date,
    readonly periodicity: Periodicity
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      periodicity: this.periodicity.toJson()
    });
  }
}
