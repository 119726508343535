import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticatedUser } from '../core/authenticated';
import { User } from '../users';
import { Principal } from './current-info';
import { CurrentInfoService } from './current-info.service';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  public readonly $user: Observable<User>;

  public readonly $principal: Observable<Principal>;

  public readonly $principalStr: Observable<string>;

  constructor(currentInfo: CurrentInfoService) {
    this.$user = currentInfo.$value.pipe(
      map((v) => v.authenticated),
      filter((v) => v instanceof AuthenticatedUser),
      map((v) => (v as AuthenticatedUser).user)
    );
    this.$principal = currentInfo.$value.pipe(map((v) => v.principal));
    this.$principalStr = currentInfo.$value.pipe(map((v) => v.principalStr));
  }
}
