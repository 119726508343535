import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { MathUtils } from '@eceos/common-utils';
import { Operatable } from '../operatables/operatable';
import { VendorSummary } from '../vendors/vendor';
import { ClientSummary } from './../clients/client';
import { SalesOrderState } from './sales-order-state';
import { PaymentForm } from '../sales/payment-form';
import { Freight, WithoutFreight, FreightWithValue } from '../sales/freight/freight';
import { PaymentDiscount } from '../sales/payment-discount';

export class SalesOrderSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly key = null,
    public date: Date = null,
    public state = SalesOrderState.PENDENTE,
    public client: ClientSummary = null,
    public vendor: VendorSummary = null,
    public value: number = 0
  ) {}

  get hasKey(): boolean{
    return this.key && this.key.trim() && this.key.trim().length > 0;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      state: this.state.name,
      client: this.client.toJson(),
      vendor: SgvJson.to.optional(this.vendor)
    });
  }

  static fromJson(json: any): SalesOrderSummary {
    return json
      ? SgvJson.from.simple(json, SalesOrderSummary, {
          state: SalesOrderState.get(json.state),
          client: ClientSummary.fromJson(json.client),
          vendor: VendorSummary.fromJson(json.vendor)
        })
      : null;
  }
}

export class SalesOrder implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly key = null,
    public date: Date = new Date(),
    public state = SalesOrderState.PENDENTE,
    public client: ClientSummary = null,
    public vendor: VendorSummary = null,
    public items: SalesOrderItem[] = [],
    public itemsSortingOrder: SalesOrderItemSortingOrder = SalesOrderItemSortingOrder.DEFAULT,
    public payment: PaymentForm = null,
    public freight: Freight = new WithoutFreight(),
    public discount: PaymentDiscount = new PaymentDiscount(),
    public details: string = '',
  ) {}

  get hasKey(): boolean{
    return this.key && this.key.trim() && this.key.trim().length > 0;
  }

  get itemsSorted(): SalesOrderItem[] {
    return this.items.slice().sort(this.itemsSortingOrder.compareFn);
  }

  get total(): number {
    return this.totalOfItems + this.freightValue - this.totalDiscountOfItems;
  }

  get hasTotal(): boolean {
    return this.total > 0;
  }

  get totalOfItems(): number {
    return this.items.map((i) => i.total).reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get freightValue(): number {
    return this.freight && this.freight instanceof FreightWithValue && this.freight.value > 0
      ? this.freight.value
      : 0;
  }

  get totalPriceWithoutDiscount(): number {
    return this.totalOfItems + this.freightValue;
  }

  get hasTotalPriceWithoutDiscount(): boolean {
    return this.totalPriceWithoutDiscount > 0;
  }

  get totalPriceWithoutFreight(): number {
    return this.discount.applyOn(this.totalOfItems);
  }

  get totalPriceWithoutFreightAndDiscount(): number {
    return this.totalOfItems;
  }

  get hasTotalPriceWithoutFreightAndDiscount(): boolean {
    return this.totalPriceWithoutFreightAndDiscount > 0;
  }

  get totalDiscountOfItems(): number {
    return this.discount.getValue(this.totalPriceWithoutDiscount);
  }

  get canApprove(): boolean {
    return Boolean(this.isPending && this.items && this.items.length > 0);
  }

  get isApproved(): boolean {
    return this.state === SalesOrderState.APROVADO;
  }

  get isCanceled(): boolean {
    return this.state === SalesOrderState.CANCELADO;
  }

  get isDenied(): boolean {
    return this.state === SalesOrderState.NEGADO;
  }

  get isFinalized(): boolean {
    return this.state === SalesOrderState.FINALIZADO;
  }

  get isPending(): boolean {
    return this.state === SalesOrderState.PENDENTE;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      state: this.state.name,
      client: this.client.toJson(),
      vendor: SgvJson.to.optional(this.vendor),
      items: SgvJson.to.array(this.items),
      payment: SgvJson.to.optional(this.payment),
      freight: SgvJson.to.optional(this.freight),
      discount: SgvJson.to.optional(this.discount),
      itemsSortingOrder: SgvJson.to.optional(this.itemsSortingOrder)
    });
  }

  static fromJson(json: any): SalesOrder {
    return json
      ? SgvJson.from.simple(json, SalesOrder, {
          state: SalesOrderState.get(json.state),
          client: ClientSummary.fromJson(json.client),
          vendor: VendorSummary.fromJson(json.vendor),
          items: SgvJson.from.array(json.items, SalesOrderItem.fromJson),
          payment: PaymentForm.fromJson(json.payment),
          freight: Freight.fromJson(json.freight),
          discount: PaymentDiscount.fromJson(json.discount),
          itemsSortingOrder: SalesOrderItemSortingOrder.get(json.itemsSortingOrder)
        })
      : null;
  }
}

export class SalesOrderItem implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public operatable: Operatable = null,
    public amount: number = 1,
    public unitaryPrice: number = 0,
    public name: string = ''
  ) {
    if (operatable && !unitaryPrice) {
      this.unitaryPrice = operatable.defaultSellValue || 0;
    }
    if(operatable && !name){
      this.name = operatable.name;
    }
  }

  get total(): number {
    return MathUtils.round(this.amount * this.unitaryPrice);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { operatable: this.operatable.toJson() });
  }

  static fromJson(json: any): SalesOrderItem {
    return json
      ? SgvJson.from.simple(json, SalesOrderItem, {
          operatable: Operatable.fromJson(json.operatable)
        })
      : null;
  }
}

export class SalesOrderItemSortingOrder implements DomainEntity {
  static DEFAULT = new SalesOrderItemSortingOrder('DEFAULT', 'Sem ordenação', () => 1);

  static ALPHABETICAL_ASCENDING = new SalesOrderItemSortingOrder(
    'ALPHABETICAL_ASCENDING',
    'Ordenar em ordem alfabética [A-Z]',
    (item, other) =>
      item.operatable && other.operatable
        ? item.operatable.name.localeCompare(other.operatable.name)
        : 0
  );

  static ALPHABETICAL_DESCENDING = new SalesOrderItemSortingOrder(
    'ALPHABETICAL_DESCENDING',
    'Ordenar em ordem alfabética [Z-A]',
    (item, other) =>
      item.operatable && other.operatable
        ? other.operatable.name.localeCompare(item.operatable.name)
        : 0
  );

  private constructor(
    readonly id: string,
    readonly label: string,
    readonly compareFn: (item: SalesOrderItem, other: SalesOrderItem) => number
  ) {}

  toJson(): string {
    return this.id;
  }

  static get(id: string): SalesOrderItemSortingOrder {
    return SalesOrderItemSortingOrder.values().find((it) => it.id === id);
  }

  static values(): SalesOrderItemSortingOrder[] {
    return [
      SalesOrderItemSortingOrder.DEFAULT,
      SalesOrderItemSortingOrder.ALPHABETICAL_ASCENDING,
      SalesOrderItemSortingOrder.ALPHABETICAL_DESCENDING
    ];
  }
}
