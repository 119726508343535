<div class="cookie">
  <p class="cookie-text">
    Este site armazena cookies para melhorar sua experiência. Para maiores informações consulte a
    nossa
    <a
      href="https://docs.google.com/document/u/1/d/e/2PACX-1vTxtAfe-jSf0qisY6U9X2E2XOlDS2sdMOM7lx0vuMUvLu0ThIorexwU2el4nCQNTozUmUMCBDIkFbUi/pub"
      target="_blank"
    >
      política de privacidade</a
    >.
</p>
<button mat-raised-button (click)="onOkClick()" class="cookie-btn">Ok, estou de acordo</button>
</div>
