interface Mask {
  mask: string;
  digits: number;
}
export class NumberMask {
  readonly masks: Mask[];

  constructor(masks: string[] | string) {
    if (Array.isArray(masks)) {
      const arrMasks = masks as string[];
      this.masks = arrMasks.map(m => this.mapMaskDigits(m)).sort((s1, s2) => s1.digits - s2.digits);
    } else {
      this.masks = [this.mapMaskDigits(masks as string)];
    }
  }

  apply(value: string) {
    return value ? this.applyMask(this.getMask(value), value) : value;
  }

  getMask(value: string): Mask {
    if (this.masks.length <= 0) {
      return { mask: '', digits: 0 };
    } else if (this.masks.length === 1) {
      return this.masks[0];
    }
    const unmaskedValue = value.replace(/\D/g, '');
    const filtered = this.masks.filter(m => m.digits >= unmaskedValue.length);
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return this.masks[this.masks.length - 1];
    }
  }

  concat(another: NumberMask) {
    return new NumberMask([...this.masks, ...another.masks].map(m => m.mask));
  }

  private mapMaskDigits(mask: string) {
    return { mask, digits: mask.replace(/\D/g, '').length };
  }
  private applyMask(mask: Mask, valorMask: string) {
    const maskStr = mask.mask;
    if (valorMask.length === 0) {
      return '';
    } else {
      let valorMaskPos = 0;
      let valor = '';
      for (let i = 0; i < maskStr.length; i++) {
        if (isNaN(parseInt(maskStr.charAt(i), 10))) {
          valor += maskStr.charAt(i);
        } else {
          const next = valorMask[valorMaskPos++];
          if (next) {
            valor += next;
          } else {
            break;
          }
        }
      }

      if (valor.indexOf('_') > -1) {
        valor = valor.substr(0, valor.indexOf('_'));
      }
      return valor;
    }
  }
  static compose(...all: NumberMask[]): NumberMask {
    return all.reduce((v, acc) => acc.concat(v));
  }
}
