import { Observable, throwError, timer } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

export const navigateIf = (predicate: (next: any) => boolean, navigate: (next: any) => void) =>
  tap((next: any) => {
    if (predicate(next)) navigate(next);
  });

export const mapToNumber = (precision: number = 10) =>
  map((next: any) => Number.parseInt(next || '0', precision));

export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
        return throwError(error);
      }
      return timer(retryAttempt * scalingDuration);
    })
  );
};

export const retryExceptOnClientError = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (retryAttempt > maxRetryAttempts || (error.status >= 400 && error.status <= 499)) {
        return throwError(error);
      }
      return timer(retryAttempt * scalingDuration);
    })
  );
};
