import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBarcode]'
})
export class BarcodeDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    if (e.ctrlKey && e.keyCode === 74) {
      e.preventDefault();
    }
  }
}
