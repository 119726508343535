import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApiModule,
  API_ROOT_URL,
  ArchModule,
  AuthModule,
  AUTH_TOKEN_NAME,
  HttpErrorHandler,
  HttpErrorMessageHandler,
  LOGIN_ROUTE
} from '@eceos/arch';
import { AssetsModule } from '@eceos/assets';
import { COOKIE_NAME } from '@eceos/common-components';
import { DomainModule } from '@eceos/domain';
import { FontAwesomeRegister } from '@eceos/shared-third-party';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DomainModule,
    ApiModule,
    AuthModule,
    ArchModule,
    AssetsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    { provide: AUTH_TOKEN_NAME, useValue: 'vaccines-pda.authToken' },
    { provide: COOKIE_NAME, useValue: 'vaccines-pda.cookieConsent' },
    { provide: LOGIN_ROUTE, useValue: '/login' },
    { provide: API_ROOT_URL, useValue: environment.apiRoot },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    { provide: HttpErrorHandler, useClass: HttpErrorMessageHandler, multi: true },
  ]
})
export class CoreModule {
  constructor(registry: FontAwesomeRegister) {
    registry.registerAll(faUserCircle);
  }
}
