import { Inject, Injectable, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import * as conf from './auth.config';
import { AuthManagerService, AuthService } from './auth.service';

const DEFAULT_LOGIN_ROUTE = '/login';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  private loginPath: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private authManagerService: AuthManagerService,
    @Inject(conf.LOGIN_ROUTE) @Optional() loginPath: string
  ) {
    this.loginPath = loginPath || DEFAULT_LOGIN_ROUTE;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.hasToken || this.authManagerService.hasUserToken) {
      return true;
    } else {
      this.router.navigate([this.loginPath], { queryParams: { src: btoa(state.url) } });
    }
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
