import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const EVENT_TYPE_ONLINE = 'online';
const EVENT_TYPE_OFFLINE = 'offline';
// Avaliar implementação futura baseada em https://github.com/ifeelpanic/ngx-connection-status/blob/master/src/lib/services/ngx-connection-status.service.ts

@Injectable({ providedIn: 'root' })
export class OnlineStatusService implements OnDestroy {
  private _online = new BehaviorSubject<boolean>(true);

  constructor() {
    window.addEventListener(EVENT_TYPE_ONLINE, () => this.onOnline());
    window.addEventListener(EVENT_TYPE_OFFLINE, () => this.onOffline());
  }

  ngOnDestroy() {
    window.removeEventListener(EVENT_TYPE_ONLINE, () => this.onOnline());
    window.removeEventListener(EVENT_TYPE_OFFLINE, () => this.onOffline());
  }

  get $online(): Observable<boolean> {
    return this._online.asObservable();
  }

  private onOnline() {
    this._online.next(true);
  }

  private onOffline() {
    this._online.next(false);
  }
}
