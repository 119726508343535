import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { DoseSummary } from '../doses';
import { Doctor } from '../doctors';
import { BodySiteSummary } from '../body-sites';
import { Operatable } from '../../operatables';
import { VaccineTypeSummary } from '../vaccine-types';

export class VaccineApplicationItem implements DomainEntity {
  public readonly id = SgvId.gen();
  type: VaccineTypeSummary = null;
  dose: DoseSummary = null;
  doctor: Doctor = null;
  bodySite: BodySiteSummary = null;
  operatable: Operatable = null;
  barcodeConference = null;
  unitaryPrice = 0;

  constructor(doctor?: Doctor) {
    this.doctor = doctor;
  }

  hasConference(): boolean {
    return (
      this.barcodeConference !== null &&
      this.barcodeConference !== '' &&
      this.operatable.barcode !== null
    );
  }

  isConferenceValid(): boolean {
    if (this.hasConference()) {
      return this.barcodeConference === this.operatable.barcode;
    }
    return false;
  }

  isConferenceInvalid(): boolean {
    return !this.isConferenceValid();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: this.type.toJson(),
      dose: this.dose.toJson(),
      doctor: this.doctor === null ? null : this.doctor.toJson(),
      bodySite: this.bodySite === null ? null : this.bodySite.toJson(),
      operatable: this.operatable.toJson()
    });
  }

  valid(): boolean {
    return (
      Boolean(this.type) && Boolean(this.dose) && Boolean(this.doctor) && Boolean(this.operatable)
    );
  }

  incomplete(): boolean {
    return (
      Boolean(this.type) &&
      (!Boolean(this.dose) || !Boolean(this.doctor) || !Boolean(this.operatable))
    );
  }

  static fromJson(json: any): VaccineApplicationItem {
    return SgvJson.from.simple(json, VaccineApplicationItem, {
      type: VaccineTypeSummary.fromJson(json.type),
      dose: DoseSummary.fromJson(json.dose),
      doctor: Doctor.fromJson(json.doctor),
      bodySite: BodySiteSummary.fromJson(json.bodySite),
      operatable: Operatable.fromJson(json.operatable)
    });
  }
}
