import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { PdvProfile } from './pdv-profiles';

export class Pdv implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public name = '',
    public identifier = '',
    public description = '',
    public profile: PdvProfile = null,
    public readonly isActive = true,
    public registrationDate = new Date()
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      profile: this.profile ? this.profile.toJson() : null,
      type: 'pdv'
    });
  }

  static fromJson(json: any): Pdv {
    if (!json) return null;
    return SgvJson.from.simple(json, Pdv, {
      profile: PdvProfile.fromJson(json.profile)
    });
  }
}
