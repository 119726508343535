import { Injectable } from '@angular/core';
import {
  ApiService,
  InterceptorConfig,
  RepositoryImpl,
  UrlSearchParamsBuilder,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientSummary } from '../../clients';
import { RemotePrinterSummary } from '../../ehwhub';
import { FiscalDocumentSummary } from '../../fiscal/document';
import { ResponsibleByPatient } from './responsible-by-patient';
import { VaccineApplication } from './vaccine-application';
import { VaccineApplicationItem } from './vaccine-application-items';
import { VaccineApplicationSummary } from './vaccine-application-summary';

@Injectable({ providedIn: 'root' })
export class VaccineApplicationsRepository extends RepositoryImpl<
  VaccineApplicationSummary,
  VaccineApplication
> {
  constructor(api: ApiService) {
    super(api.root.path('/vaccines/applications'), VaccineApplicationSummary, VaccineApplication);
  }

  listPending(): Observable<VaccineApplicationSummary[]> {
    return this.api
      .path('pendingOfApplication')
      .getJson<any[]>()
      .pipe(map(r => r.map((j: any) => this.summaryType.fromJson(j))));
  }

  finalizeApplication(
    value: VaccineApplication,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<VaccineApplicationItem[]> {
    const jsonOfItems = value.items.map(it => it.toJson());

    return this.api
      .path('finalizeApplication')
      .path(value.id)
      .putJson<VaccineApplicationItem[]>(jsonOfItems, {
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      });
  }

  printLabels(
    remotePrinter: RemotePrinterSummary,
    value: VaccineApplication,
    items: VaccineApplicationItem[],
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<VaccineApplicationItem[]> {
    const jsonOfItems = items.map(it => it.toJson());

    return this.api
      .path('printLabel')
      .path(remotePrinter.id)
      .path(value.id)
      .postJson<VaccineApplicationItem[]>(jsonOfItems, {
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      });
  }
  getResponsibleByPatient(patientId: string): Observable<ResponsibleByPatient> {
    return this.api
      .path('responsibleByPatient')
      .path(patientId)
      .getJson()
      .pipe(map(r => ResponsibleByPatient.fromJson(r)));
  }

  getClientResponsibleByPatient(patientId: string): Observable<ClientSummary> {
    return this.api
      .path('clientResponsibleByPatient')
      .path(patientId)
      .getJson()
      .pipe(map(r => ClientSummary.fromJson(r)));
  }

  createFiscalDocument(
    vaccineApplication: VaccineApplication,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<FiscalDocumentSummary> {
    return this.api
      .path('fiscalDocument')
      .path(vaccineApplication.id)
      .postJson({}, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => FiscalDocumentSummary.fromJson(r)));
  }

  transmitFiscalDocument(
    fiscalDocument: FiscalDocumentSummary,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<FiscalDocumentSummary> {
    return this.api
      .path('fiscalDocument')
      .path(fiscalDocument.id)
      .path('transmit')
      .postJson({}, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => FiscalDocumentSummary.fromJson(r)));
  }

  findSummaryFiscalDocument(
    vaccineApplication: VaccineApplication | VaccineApplicationSummary
  ): Observable<FiscalDocumentSummary> {
    return this.api
      .path('fiscalDocument')
      .path(vaccineApplication.id)
      .getJson({ params: new UrlSearchParamsBuilder().summary(true).build() })
      .pipe(map(r => FiscalDocumentSummary.fromJson(r)));
  }

  printFiscalDocumentTo(
    fiscalDocument: FiscalDocumentSummary,
    printer: RemotePrinterSummary,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Response> {
    const form = new URLSearchParams();
    form.set('printerId', printer.id);
    return this.api
      .path('fiscalDocument')
      .path(fiscalDocument.id)
      .path('receipt.pdf')
      .path('printTo')
      .postForm(form, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  mailFiscalDocumentTo(
    fiscalDocument: FiscalDocumentSummary,
    mail: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Response> {
    const form = new URLSearchParams();
    form.set('mail', mail);
    return this.api
      .path('fiscalDocument')
      .path(fiscalDocument.id)
      .path('receipt.pdf')
      .path('mailTo')
      .postForm(form, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  cancelApplication(
    vaccineApplication: VaccineApplication,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Response> {
    return this.api
      .path(vaccineApplication.id)
      .path('cancelApplication')
      .postJson(
        { action: 'close' },
        { metadata: RequestMetadataUtils.merge(metadata, this.metadata) }
      );
  }
}
