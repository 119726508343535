import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class Doctor implements DomainEntity {
  constructor(readonly id = SgvId.gen(), public name = '', public crm = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): Doctor {
    return json ? SgvJson.from.simple(json, Doctor) : null;
  }
}
