export class ChartColor {
    static BLUE = new ChartColor('BLUE', 'rgb(62,152,211)', 'rgba(62,152,211,0.6)');
    static RED = new ChartColor('RED', 'rgb(239,63,97)', 'rgba(239,63,97,0.6)');
    static GREEN = new ChartColor('GREEN', 'rgb(43,182,115)', 'rgba(43,182,115,0.6)');
    static ORANGE = new ChartColor('ORANGE', 'rgb(241,87,50)', 'rgba(241,87,50,0.6)');
    static YELLOW = new ChartColor('YELLOW', 'rgb(255, 235, 59)', 'rgba(255,235,59,0.6)');
    static GRAY = new ChartColor('GRAY', 'rgb(158,158,158)', 'rgb(158,158,158,0.6)');
    static BLUE_GRAY = new ChartColor('BLUE_GRAY', 'rgb(96,125,139)', 'rgb(96,125,139,0.6)');
  
    private constructor(
      readonly id: string,
      readonly color: string,
      readonly colorWithAplha: string
    ) {}
  
    static values(): ChartColor[] {
      return [
        ChartColor.BLUE,
        ChartColor.RED,
        ChartColor.GREEN,
        ChartColor.ORANGE,
        ChartColor.YELLOW,
        ChartColor.GRAY,
        ChartColor.BLUE_GRAY
      ];
    }
  
    static colors(index?: number): string[] {
      return ChartColor.getColors(index).map((it) => it.color);
    }
  
    static colorsWithAplha(index?: number): string[] {
      return ChartColor.getColors(index).map((it) => it.colorWithAplha);
    }
  
    private static getColors(index?: number): ChartColor[] {
      const arr = ChartColor.values();
      if (index > 0) {
        const colorsLastIndex = arr.length - 1;
  
        if (index > colorsLastIndex) {
          for (let i = 0; i < Math.trunc(index / colorsLastIndex); i++) {
            arr.push(...ChartColor.values());
          }
        }
      }
      return arr;
    }
  }
  