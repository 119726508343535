import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Gender } from '../../core';
import { PaymentCovenantData } from '../paymentCovenants';
import * as moment from 'moment';

export class PatientSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly cpf = '',
    public readonly gender = Gender.NOT_INFORMED,
    public readonly birthdate = new Date(),
    public readonly paymentCovenantData: PaymentCovenantData = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      gender: this.gender.name,
      paymentCovenantData: SgvJson.to.optional(this.paymentCovenantData)
    });
  }

  static fromJson(json: any): PatientSummary {
    return json
      ? SgvJson.from.simple(json, PatientSummary, {
          birthdate: moment(json.birthdate).toDate(),
          gender: Gender.get(json.gender),
          paymentCovenantData: PaymentCovenantData.fromJson(json.paymentCovenantData)
        })
      : null;
  }
}
