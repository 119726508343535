
export class Gender {

  public static NOT_INFORMED = new Gender('NOT_INFORMED', 'Não Informado');
  public static MALE = new Gender('MALE', 'Masculino');
  public static FEMALE = new Gender('FEMALE', 'Feminino');
  public static OTHER = new Gender('OTHER', 'Outro');

  private constructor(
    public readonly name: string,
    public readonly label: string
  ) {}

  public static values(): Gender[] {
    return [
      Gender.NOT_INFORMED,
      Gender.MALE,
      Gender.FEMALE,
      Gender.OTHER
    ];
  }

  public static get(name: string): Gender {
    const gender = Gender.values().find(v => v.name === name);
    if (!gender) {
      const err = new Error(`${name} Gender is not mapped!`);
      console.error(err);
      throw err;
    }
    return gender;
  }
}
