<vpda-layout [loading]="loading">
  <mat-card class="titleCard">
    <mat-card-header>
      <mat-card-title>VACINAS PENDENTES</mat-card-title>
    </mat-card-header>
    <mat-card-content class="recordsCard">
      <mat-form-field class="filter">
        <mat-icon matPrefix>search</mat-icon>
        <input
          #filterInput
          matInput
          (keyup)="applyFilter($event.target.value)"
          placeholder="Buscar"
          autofocus
          focusDelay="200"
        />
      </mat-form-field>

      <mat-table [dataSource]="vaccineApplications" class="table">
        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef [ngClass]="'numberColumn'">Número</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'numberColumn'">
            {{ element.number }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef [ngClass]="'dateColumn'">Inicio</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'dateColumn'">
            {{ element.date | date: 'dd/MM/yyyy HH:mm' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="patient">
          <mat-header-cell *matHeaderCellDef [ngClass]="'patientColumn'">Paciente</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'patientColumn'">
            {{ element.patient.name }}, {{ element.patient.birthdate | age }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gender">
          <mat-header-cell *matHeaderCellDef [ngClass]="'genderColumn'">Sexo</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'genderColumn'">
            {{ element.patient.gender.label }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="requestedVaccines">
          <mat-header-cell *matHeaderCellDef [ngClass]="'requestedVacsColumn'">
            Vaciona(s) Solicitada(s)
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'requestedVacsColumn'">
            {{ element.vaccinesRequested }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timeOfWait">
          <mat-header-cell *matHeaderCellDef [ngClass]="'timeOfWaitColumn'">
            Tempo de Espera
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'timeOfWaitColumn'">
            {{ element.date | amTimeAgo }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          highlightTableRow
          #rowId
          *matRowDef="let row; columns: displayedColumns"
          (click)="openPending(row)"
          (touch)="openPending(row)"
        ></mat-row>
      </mat-table>
      <h2 *ngIf="vaccineApplications.data.length == 0" class="emptyMessage">
        Nenhuma vacinação pendente
      </h2>
    </mat-card-content>
  </mat-card>
</vpda-layout>
