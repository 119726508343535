import { Injectable } from '@angular/core';
import { RemotePrinterSummary } from './remote-printer';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';

@Injectable({ providedIn: 'root' })
export class RemotePrintersRepository extends ReadonlyRepositoryImpl<
  RemotePrinterSummary,
  RemotePrinterSummary
> {
  constructor(api: ApiService) {
    super(api.root.path('remoteHw/printers'), RemotePrinterSummary, RemotePrinterSummary);
  }
}
