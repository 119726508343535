import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VaccineApplication,
  VaccineApplicationItem,
  VaccineApplicationsRepository,
  VaccineApplicationState
} from '@eceos/domain';
import { switchMap } from 'rxjs/operators';
import { SettingsRepository } from '../../settings/settings.repository';

@Component({
  selector: 'vpda-applied-vaccines',
  templateUrl: './applied-vaccines.component.html',
  styleUrls: ['./applied-vaccines.component.scss']
})
export class AppliedVaccinesComponent implements OnInit {
  loading = false;

  vaccineApplication: VaccineApplication;

  items = new MatTableDataSource();

  displayedColumns = ['select', 'type', 'vaccine', 'bodySite'];

  selection = new SelectionModel<VaccineApplicationItem>(true, []);

  constructor(
    private route: ActivatedRoute,
    private repository: VaccineApplicationsRepository,
    private settingsRepository: SettingsRepository,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(params => {
          const id = params.get('id');
          return this.repository.find(id);
        })
      )
      .subscribe(
        (va: VaccineApplication) => {
          this.vaccineApplication = va;
          this.items.data = va.items;

          if (
            VaccineApplicationState[VaccineApplicationState.FINALIZED] !==
            this.vaccineApplication.state.toString()
          ) {
            this.snackBar.open(
              'Aplicação de Vacina Nº' +
                this.vaccineApplication.number +
                ' não se encontra finalizada. Não é possível iniciar impressões.',
              null,
              { duration: 3000 }
            );
            this.router.navigate(['/vaccines']);
          }
        },
        e => {
          this.vaccineApplication = null;
          this.items.data = null;
          console.error(e);
          this.router.navigate(['/vaccines']);
        }
      );
  }

  private areAllItemsValid(): boolean {
    const totalOfItems = this.vaccineApplication.items.length;
    const validItems = this.vaccineApplication.items.filter((it: VaccineApplicationItem) =>
      it.isConferenceValid()
    ).length;

    return totalOfItems === validItems;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.items.data.forEach(row => this.selection.select(<VaccineApplicationItem>row));
  }

  printBarcodes() {
    if (!this.loading) {
      this.loading = true;
      if (!this.settingsRepository.hasDefaultReceiptPrinter()) {
        this.snackBar.open('A impressora está desconectada!', null, { duration: 2000 });
        this.loading = false;
        return;
      }

      if (this.selection.selected.length === 0) {
        this.snackBar.open('Nenhum item selecionado para impressão!', null, { duration: 2000 });
        this.loading = false;
        return;
      }

      this.snackBar.open(
        this.selection.selected.length + ' item(s) selecionado(s) para impressão! Enfileirando...',
        null,
        { duration: 2000 }
      );

      this.repository
        .printLabels(
          this.settingsRepository.defaultReceiptPrinter,
          this.vaccineApplication,
          this.selection.selected
        )
        .subscribe(
          result => {
            this.snackBar.open(
              this.selection.selected.length + ' item(s) foram enviados a impressão com sucesso!',
              null,
              { duration: 2000 }
            );
            this.loading = false;
          },
          e => {
            this.snackBar.open('Erro em impressões (' + e.error + ').', null, { duration: 2000 });
            console.log(e);
            this.loading = false;
          }
        );
    }
  }
}
