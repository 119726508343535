import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { ProfileSumary } from '../profiles/profile-sumary';

export class User implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public login = '',
    public name = '',
    public insertionDate = new Date(),
    public profile: ProfileSumary = null,
    public password = '',
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      profile: this.profile ? this.profile.toJson() : null,
      type: 'user'
    });
  }

  static fromJson(json: any): User {
    if (!(json || json.type || json.profile)) return null;

    switch (json.type) {
      case 'externalUser':
        return ExternalUser.fromJson(json);
      case 'user':
        return SgvJson.from.simple(json, User, { profile: ProfileSumary.fromJson(json.profile) });
      default:
        throw new Error('Operation not mapped');
    }
  }
}

export class ExternalUser extends User {
  constructor(
    readonly id = SgvId.gen(),
    login = '',
    name = '',
    insertionDate = new Date(),
    profile: ProfileSumary = null,
    password = '',
    readonly version = 0,
    readonly isActive = true,
    public email = ''
  ) {
    super(id, login, name, insertionDate, profile, password, version, isActive);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      profile: this.profile?.toJson(),
      type: 'externalUser'
    });
  }

  static fromJson(json: any): User {
    if (!(json || json.profile)) return null;
    return  SgvJson.from.simple(json, ExternalUser, { profile: ProfileSumary.fromJson(json.profile) })
  }
}
