import { Action } from '@eceos/arch';
export class NfeAction implements Action {
  private constructor(public readonly id: string) {}

  toJson() {
    return this.id;
  }

  static readonly SEND = new NfeAction('SEND');
  static readonly CONSULT = new NfeAction('CONSULT');
  static readonly CANCEL = new NfeAction('CANCEL');
  static readonly SIMPLE_NFE_ITEM = new NfeAction('SIMPLE_NFE_ITEM');
  static readonly OPERATABLE_NFE_ITEM = new NfeAction('OPERATABLE_NFE_ITEM');
  static readonly DOWNLOAD_NFE_LOT = new NfeAction('DOWNLOAD_NFE_LOT');

  static readonly ALL = [
    NfeAction.SEND,
    NfeAction.CONSULT,
    NfeAction.CANCEL,
    NfeAction.SIMPLE_NFE_ITEM,
    NfeAction.OPERATABLE_NFE_ITEM,
    NfeAction.DOWNLOAD_NFE_LOT
  ];
}
