import { SgvJson, SgvId } from '@eceos/arch';
import { Permission } from '../core/permission/permission';

export class Profile {
  constructor(
    public id = SgvId.gen(),
    public name = '',
    public permissions: Permission[] = [],
    readonly version = 0,
    readonly isActive = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      permissions: SgvJson.to.array(this.permissions)
    });
  }

  static fromJson(json: any): Profile {
    if (!(json || json.type)) return null;

    switch (json.type) {
      case 'adminProfile':
        return AdminProfile.fromJson(json);
      case 'profile':
        return SgvJson.from.simple(json, Profile, {
          permissions: json.permissions
            ? json.permissions.map(p => Permission.fromJson(p)).filter(r => r !== null)
            : null
        })
      default:
        throw new Error('Operation not maped');
    }
  }
}

export class AdminProfile extends Profile {
  constructor(
    public id = SgvId.gen(),
    public name = '',
    public permissions: Permission[] = [],
    readonly version = 0,
    readonly isActive = true
  ) {
    super(id, name, permissions, version, isActive);
  }

  static fromJson(json: any): Profile {
    if (!(json || json.profile)) return null;
    return SgvJson.from.simple(json, AdminProfile, {
      permissions: json.permissions
        ? json.permissions.map(p => Permission.fromJson(p)).filter(r => r !== null)
        : null
    })
  }
}
