export class EntityCrudState{
  public static LIST = new EntityCrudState('LIST');
  public static VIEW = new EntityCrudState('VIEW');
  public static INSERT = new EntityCrudState('INSERT');
  public static EDIT = new EntityCrudState('EDIT');

  private constructor(public readonly name: string){}

  static get(name: string): EntityCrudState{
    return name ? this.values().find(v => v.name === name.toUpperCase()) : null;
  }

  static values():EntityCrudState[]{
    return [
      EntityCrudState.LIST,
      EntityCrudState.VIEW,
      EntityCrudState.INSERT,
      EntityCrudState.EDIT
    ];
  }
}
