export class FiscalDocumentState {
  static PENDING = new FiscalDocumentState('PENDING', 'Pendente');
  static PROCESSING = new FiscalDocumentState('PROCESSING', 'Processando');
  static AUTHORIZED = new FiscalDocumentState('AUTHORIZED', 'Autorizada');
  static CANCELED = new FiscalDocumentState('CANCELED', 'Cancelada');

  private constructor(readonly name: string, readonly label: string) {}

  static get(name: string): FiscalDocumentState {
    const value = FiscalDocumentState.values().find(e => e.name === name);
    if (!value) {
      const err = new Error(`${name} FiscalDocumentState is not mapped!`);
      console.error(err);
      throw err;
    }
    return value;
  }

  static getOrNull(name: string): FiscalDocumentState {
    return FiscalDocumentState.values().find(e => e.name === name);
  }

  static values(): FiscalDocumentState[] {
    return [
      FiscalDocumentState.PENDING,
      FiscalDocumentState.PROCESSING,
      FiscalDocumentState.AUTHORIZED,
      FiscalDocumentState.CANCELED
    ];
  }
}
