import { Filter } from './filter';
import { PageData } from './page-data';
import { SortOrder } from './sort-order';
import { HttpParams } from '@angular/common/http';
import { Actives } from './actives';

export class UrlSearchParamsBuilder {
  private readonly PARAM_QUERY = 'q';
  private readonly PARAM_PAGE_FIRST = '_pageFirst';
  private readonly PARAM_PAGE_SIZE = '_pageSize';
  private readonly PARAM_SORT = '_sort_{index}_{field}';
  private readonly PARAM_FILTER = '{field}_{operation}';
  private readonly PARAM_IGNORE_IDS = '_ignoreIds';
  private readonly PARAM_SUMMARY = '_summary';
  private readonly PARAM_ACTIVES = '_actives';

  constructor(private params = new HttpParams()) {}

  query(query: string): UrlSearchParamsBuilder {
    if (query !== '') {
      this.params = this.params.set(this.PARAM_QUERY, query);
    }
    return this;
  }

  filters(filters: Filter[]): UrlSearchParamsBuilder {
    for (const filter of filters) {
      let key = filter.field;
      if (filter.operatorStr) {
        key = this.PARAM_FILTER;
        key = key.replace('{field}', filter.field);
        key = key.replace('{operation}', filter.operatorStr);
      }

      this.params = this.params.set(key, filter.valueStr);
    }
    return this;
  }

  sortOrders(sortOrders: SortOrder[]): UrlSearchParamsBuilder {
    let i = 1;
    for (const sort of sortOrders) {
      let key = this.PARAM_SORT;
      key = key.replace('{index}', (i++).toString());
      key = key.replace('{field}', sort.field);
      this.params = this.params.set(key, sort.sortModeStr);
    }
    return this;
  }

  page(pageData: PageData): UrlSearchParamsBuilder {
    if (pageData.enabled) {
      this.params = this.params.set(this.PARAM_PAGE_FIRST, pageData.first.toString());
      this.params = this.params.set(this.PARAM_PAGE_SIZE, pageData.rows.toString());
    }
    return this;
  }

  ignoreIds(ids: string[]): UrlSearchParamsBuilder {
    if (ids && ids.length > 0) {
      this.params.set(this.PARAM_IGNORE_IDS, ids.join(','));
    }
    return this;
  }

  summary(summary: boolean) {
    this.params = this.params.set(this.PARAM_SUMMARY, `${summary}`);
    return this;
  }

  actives(value: Actives = Actives.TRUE) {
    this.params = this.params.set(this.PARAM_ACTIVES, value);
    return this;
  }

  build(): HttpParams {
    return this.params;
  }
}
