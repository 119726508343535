export interface JsonEntity {
  toJson(): any;
}
export namespace SgvJson {
  export namespace from {
    export function existing<T>(json: any, obj: T, exceptions: any = {}): T {
      return Object.assign(obj, json, exceptions);
    }
    export function simple<T>(json: any, clazz: new () => T, exceptions: any = {}): T {
      return json ? Object.assign(new clazz(), json, exceptions) : null;
    }

    export function array<T>(json: any, deserializer: (o: any) => T): T[] {
      return Array.isArray(json) ? json.map(deserializer).filter(v => Boolean(v)) : [];
    }
  }
  export namespace to {
    export function optional<T extends JsonEntity>(e: T, defaultValue: any = void 0): any {
      return e ? e.toJson() : defaultValue;
    }

    export function simple<T extends JsonEntity>(e: T, exceptions: any = {}): any {
      return Object.assign({}, e, exceptions);
    }

    export function array<T extends JsonEntity>(e: T[]): any[] {
      return e.map(d => d.toJson());
    }
  }
}
