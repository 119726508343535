import { Component, OnInit } from '@angular/core';
import { RemotePrintersRepository, RemotePrinterSummary } from '@eceos/domain';
import { Observable } from 'rxjs';
import { SettingsRepository } from '../settings.repository';

@Component({
  selector: 'vpda-printer-settings',
  templateUrl: './printer-settings.component.html',
  styleUrls: ['./printer-settings.component.scss']
})
export class PrinterSettingsComponent implements OnInit {
  readonly printers: Observable<RemotePrinterSummary[]>;

  constructor(
    private repository: RemotePrintersRepository,
    private settingsRepository: SettingsRepository
  ) {
    this.printers = this.repository.list();
  }

  ngOnInit() {}

  get defaultReceiptPrinter(): RemotePrinterSummary {
    return this.settingsRepository.defaultReceiptPrinter;
  }

  get printerRepository(): RemotePrintersRepository {
    return this.repository;
  }

  onPrinterSelected(value: RemotePrinterSummary) {
    this.settingsRepository.defaultReceiptPrinter = value;
  }

  compareById(obj1: RemotePrinterSummary, obj2: RemotePrinterSummary) {
    return obj1 === obj2 || (obj1 && obj2 && obj1.id === obj2.id);
  }
}
