import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { PatientSummary } from './../patients/patient-summary';
import { ResponsibleByPatient, OtherResponsible, SelfResponsible } from './responsible-by-patient';
import { VaccineApplicationItem } from './vaccine-application-items';
import {
  PaymentForm,
  PaymentDiscount,
  PaymentValueDiscount,
  PaymentPercentDiscount,
  DeferredPaymentForm,
  CashPaymentForm
} from '../../sales';
import {
  PaymentCovenantSummary,
  PaymentCovenantData,
  PaymentCovenantDefaultData
} from '../paymentCovenants';
import { Periodicity } from '../../core/periodicities/periodicity';
import { VaccineApplicationState } from './vaccine-application-state';
export class VaccineApplication implements DomainEntity {
  public readonly id = SgvId.gen();
  date = new Date();
  number = -1;
  patient: PatientSummary = null;
  responsibleByPatient: ResponsibleByPatient = null;
  items: VaccineApplicationItem[] = [];
  payment: PaymentForm = null;
  discount: PaymentDiscount = new PaymentValueDiscount();
  paymentCovenant: PaymentCovenantSummary = null;
  paymentCovenantData: PaymentCovenantData = new PaymentCovenantDefaultData();
  state = VaccineApplicationState.PENDING_APPLICATION;

  constructor(patient?: PatientSummary) {
    this.patient = patient;
    if (this.patient) {
      this.paymentCovenantData = this.patient.paymentCovenantData;
    }
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      patient: this.patient.toJson(),
      responsibleByPatient: SgvJson.to.optional(this.responsibleByPatient),
      payment: SgvJson.to.optional(this.payment),
      discount: SgvJson.to.optional(this.discount),
      paymentCovenant: this.paymentCovenant ? this.paymentCovenant.toJson() : null,
      paymentCovenantData: SgvJson.to.optional(this.paymentCovenantData)
    });
  }

  get total(): number {
    if (this.hasPaymentCovenant() && this.paymentCovenant.charged) {
      return 0.0;
    }

    return this.items.map(i => i.unitaryPrice).reduce((vAnt, vAt, i, array) => vAnt + vAt, 0.0);
  }

  get netValue(): number {
    return this.total - this.discount.getValue(this.total);
  }

  get otherResponsible(): OtherResponsible {
    return this.isOtherResponsible ? (this.responsibleByPatient as OtherResponsible) : null;
  }

  get paymentPercentDiscount(): PaymentPercentDiscount {
    return this.isPaymentPercentDiscount ? (this.discount as PaymentPercentDiscount) : null;
  }

  get paymentValueDiscount(): PaymentValueDiscount {
    return this.isPaymentValueDiscount ? (this.discount as PaymentValueDiscount) : null;
  }

  get cashPaymentForm(): CashPaymentForm {
    return this.isCashPaymentForm ? (this.payment as CashPaymentForm) : null;
  }

  get deferredPaymentForm(): DeferredPaymentForm {
    return this.asDeferredPaymentForm ? (this.payment as DeferredPaymentForm) : null;
  }

  get applicationStatus(): string {
    return this.state ? this.state : null;
  }

  periodicityType(
    types: { name: string; action: () => Periodicity }[]
  ): { name: string; action: () => Periodicity } {
    return this.deferredPaymentForm.periodicity
      ? types.find(
          t => t.action().constructor.name === this.deferredPaymentForm.periodicity.constructor.name
        )
      : null;
  }

  asCashPaymentForm(): void {
    if (!(this.payment instanceof CashPaymentForm)) {
      this.payment = new CashPaymentForm();
    }
  }

  asDeferredPaymentForm(): void {
    if (!(this.payment instanceof DeferredPaymentForm)) {
      this.payment = new DeferredPaymentForm();
    }
  }

  withoutPayment(): void {
    this.payment = null;
    this.discount = new PaymentValueDiscount();
  }

  hasPaymentCovenant(): boolean {
    return Boolean(this.paymentCovenant);
  }

  hasPaymentCovenantCharged(): boolean {
    return this.hasPaymentCovenant() && this.paymentCovenant.charged;
  }

  isCashPaymentForm(): boolean {
    return this.payment instanceof CashPaymentForm;
  }

  isDeferredPaymentForm(): boolean {
    return this.payment instanceof DeferredPaymentForm;
  }

  asPaymentValueDiscount(value: number = 0): void {
    this.discount = new PaymentValueDiscount(value);
  }

  asPaymentPercentDiscount(value: number = 0): void {
    this.discount = new PaymentPercentDiscount(value);
  }

  isPaymentValueDiscount(): boolean {
    return this.discount instanceof PaymentValueDiscount;
  }

  isPaymentPercentDiscount(): boolean {
    return this.discount instanceof PaymentPercentDiscount;
  }

  newItem(): VaccineApplicationItem {
    const item = new VaccineApplicationItem();
    this.items.push(item);
    return item;
  }

  removeItem(item: VaccineApplicationItem): void {
    this.items = this.items.filter(obj => obj !== item);
  }

  isOtherResponsible(): boolean {
    return this.responsibleByPatient instanceof OtherResponsible;
  }

  isSelfResponsible(): boolean {
    return this.responsibleByPatient instanceof SelfResponsible;
  }

  static fromJson(data: any): VaccineApplication {
    return data
      ? SgvJson.from.simple(data, VaccineApplication, {
          patient: PatientSummary.fromJson(data.patient),
          responsibleByPatient: ResponsibleByPatient.fromJson(data.responsibleByPatient),
          items: SgvJson.from.array(data.items, VaccineApplicationItem.fromJson),
          payment: PaymentForm.fromJson(data.payment),
          discount: PaymentDiscount.fromJson(data.discount),
          paymentCovenant: PaymentCovenantSummary.fromJson(data.paymentCovenant),
          paymentCovenantData: PaymentCovenantData.fromJson(data.paymentCovenantData)
        })
      : null;
  }
}
