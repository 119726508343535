import { BodySiteSummary } from './body-site';
import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';

@Injectable({
  providedIn: 'root'
})
export class BodySitesRepository extends ReadonlyRepositoryImpl<BodySiteSummary, BodySiteSummary> {
  constructor(api: ApiService) {
    super(api.root.path('vaccines').path('bodySites'), BodySiteSummary, BodySiteSummary);
  }
}
