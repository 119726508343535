import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive()
export class RequiredHandler {
  private _required = false;
  constructor(private ngControl?: NgControl) {}

  setControlValueAccessor(valueAcessor: ControlValueAccessor) {
    if(this.ngControl){
      this.ngControl.valueAccessor = valueAcessor;
    }
  }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  get required(): boolean {
    if (this._required) {
      return this._required;
    }

    if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
      const emptyValueControl = Object.assign({}, this.ngControl.control);
      (emptyValueControl as any).value = null;
      return 'required' in (this.ngControl.control.validator(emptyValueControl) || {});
    }
    return false;
  }
}
