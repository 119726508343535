export class NFeExportType {

    public static XML = new NFeExportType("XML", "xml");
    public static PDF = new NFeExportType("PDF", "pdf");
    public static CSV = new NFeExportType("CSV", "csv");
    public static SPREADSHEET = new NFeExportType("SPREADSHEET", "spreadsheet");


    private constructor(
        public readonly type: string,
        public readonly label: string
    ) { }

    public static values(): NFeExportType[] {
        return [
            NFeExportType.XML,
            NFeExportType.PDF,
            NFeExportType.CSV,
            NFeExportType.SPREADSHEET
        ]
    }

    public static get(type: string): NFeExportType {
        const nfeExportType = NFeExportType.values().find(v => v.type == type);

        if (!nfeExportType) throw new Error(`${nfeExportType} not mapped!`);
        return nfeExportType;
    }

}