export class LoadQueue {
  private queue: LoadIndicator[] = [];
  enqueue(): LoadIndicator {
    const i = new LoadIndicator();
    this.queue.push(i);
    return i;
  }
  markCount(indicator: LoadIndicator) {
    indicator.count = true;
    this.checkOk();
  }

  markList(indicator: LoadIndicator) {
    indicator.list = true;
    this.checkOk();
  }

  markFinished(indicator: LoadIndicator) {
    indicator.count = true;
    indicator.list = true;
    this.checkOk();
  }

  checkOk() {
    this.queue = this.queue.filter(i => !i.ok);
  }

  get empty() {
    return this.queue.length === 0;
  }
}

export class LoadIndicator {
  count = false;
  list = false;
  date = new Date();
  get ok() {
    return this.count && this.list;
  }
}
