import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@eceos/arch';
import { VaccineApplicationComponent } from './application/vaccine-application.component';
import { AppliedVaccinesComponent } from './applied/applied-vaccines.component';
import { PendingVaccinesComponent } from './pending/pending-vaccines.component';

export const VaccinesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  {
    path: 'vaccines',
    component: PendingVaccinesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vaccines/:id',
    component: VaccineApplicationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vaccines/applied/:id',
    component: AppliedVaccinesComponent,
    canActivate: [AuthGuard]
  }
]);
