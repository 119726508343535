import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

type FileUploadMode = 'single' | 'multi';

@Component({
  selector: 'app-file-upload-area',
  templateUrl: './file-upload-area.component.html',
  styleUrls: ['./file-upload-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadAreaComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @Output() fileSelected = new EventEmitter<File>();

  @Input() mode: FileUploadMode = 'single';

  @HostBinding('class.fileover') fileOver: boolean;

  constructor() {}

  get isSingle() {
    return this.mode === 'single';
  }

  get isMulti() {
    return this.mode === 'multi';
  }

  ngOnInit(): void {}

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.onFileReceived(evt.dataTransfer.files);
  }

  onFileSelected(files: FileList) {
    this.onFileReceived(files);
  }

  private onFileReceived(files: FileList) {
    if (files.length >= 0) {
      if (this.isSingle) {
        this.fileSelected.emit(files.item(0));
      } else {
        for (let i = 0; i < files.length; i++) {
          this.fileSelected.emit(files.item(i));
        }
      }
    }
    this.fileInput.nativeElement.value = '';
  }
}
