
export class NFeFinality {

  public static NORMAL = new NFeFinality('NORMAL', 'Normal');
  public static COMPLEMENTARY = new NFeFinality('COMPLEMENTARY', 'Complementar');
  public static ADJUSTMENT = new NFeFinality('ADJUSTMENT', 'Ajuste');
  public static RETURN = new NFeFinality('RETURN', 'Devolução/Retorno');

  private constructor(
    public readonly name: string,
    public readonly label: string
  ) {}

  public static values(): NFeFinality[] {
    return [
      NFeFinality.NORMAL,
      NFeFinality.COMPLEMENTARY,
      NFeFinality.ADJUSTMENT,
      NFeFinality.RETURN
    ];
  }

  public static get(name: String): NFeFinality {
    const finality = NFeFinality.values().find(v => v.name === name);
    if (!finality) {
      const err = new Error(`${name} NFeFinality is not mapped!`);
      console.error(err);
      throw err;
    }
    return finality;
  }
}
