import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { PrinterSettingsComponent } from './printer-settings/printer-settings.component';
import { SettingsComponent } from './settings.component';
import { SettingsRoutes } from './settings.routes';

@NgModule({
  imports: [CommonModule, SharedModule, SettingsRoutes],
  declarations: [SettingsComponent, PrinterSettingsComponent]
})
export class SettingsModule {}
