import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { PaymentDiscount } from '../sales';
import { DeliveryPolicySummary } from './../sales/delivery/policy/delivery-policy';

export class PdvProfile implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public maxDiscount = new PaymentDiscount(),
    public restrictPriceChange = false,
    public withDelivery = false,
    public deliveryPolicy: DeliveryPolicySummary = null
  ) {}

  readonly isActive = true;

  toJson() {
    return SgvJson.to.simple(this, {
      maxDiscount: SgvJson.to.optional(this.maxDiscount),
      deliveryPolicy: SgvJson.to.optional(this.deliveryPolicy)
    });
  }

  static fromJson(json: any): PdvProfile {
    return json
      ? SgvJson.from.simple(json, PdvProfile, {
          maxDiscount: PaymentDiscount.fromJson(json.maxDiscount),
          deliveryPolicy: DeliveryPolicySummary.fromJson(json.deliveryPolicy)
        })
      : null;
  }
}
