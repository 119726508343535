import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { HwHub } from './hw-hub';

export class RemotePrinterSummary implements DomainEntity {
  readonly id = SgvId.gen();
  readonly name = '';
  readonly hub: HwHub = null;
  get printerNameHub() {
    return `${this.hub.name} - ${this.name}`;
  }
  toJson(): any {
    return SgvJson.to.simple(this, {
      hub: this.hub.toJson()
    });
  }

  static fromJson(json: any): RemotePrinterSummary {
    return SgvJson.from.simple(json, RemotePrinterSummary, {
      hub: HwHub.fromJson(json.hub)
    });
  }
}
