import { HttpResponse } from '@angular/common/http';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { isValidCnpj, isValidCpf } from '@brazilian-utils/validators';
import { catchError, map, Observable, of } from 'rxjs';

export class EceosValidators {
  static readonly ieRegex = new RegExp('^([0-9]{2,14}|ISENTO)$');
  static readonly ieStRegex = new RegExp('^([0-9]{2,14})$');
  static readonly licensePlateRegex = new RegExp('[A-Z]{3}[0-9][0-9A-Z][0-9]{2}');
  static readonly identifierRegex = new RegExp('^[a-z0-9]+$');
  private constructor() {}
  static cpf(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value as string;
    if (value && value !== '' && !isValidCpf(value)) {
      return { invalidValue: { value } };
    }
    return null;
  }
  static cnpj(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value as string;
    if (value && value !== '' && !isValidCnpj(value)) {
      return { invalidValue: { value } };
    }
    return null;
  }
  static cnp(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value as string;
    if (value && value !== '' && !isValidCpf(value) && !isValidCnpj(value)) {
      return { invalidValue: { value } };
    }
    return null;
  }
  static ie(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value as string;
    if (value && value !== '' && !EceosValidators.ieRegex.test(value)) {
      return { invalidValue: { value } };
    }

    return null;
  }

  static licensePlate(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value as string;
    if (value && value !== '' && !EceosValidators.licensePlateRegex.test(value)) {
      return { invalidValue: { value } };
    }

    return null;
  }

  static ieSt(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value as string;
    if (value && value !== '' && !EceosValidators.ieStRegex.test(value)) {
      return { invalidValue: { value } };
    }

    return null;
  }

  static greaterThanEqual(limit: number): ValidatorFn {
    const validatorFn: (control: AbstractControl) => { [key: string]: any } | null = (control) => {
      const value = Number(control.value);
      if (isNaN(value) || value < limit) {
        return { invalidValue: { value } };
      }

      return null;
    };

    return (control) => validatorFn(control);
  }

  static greaterThan(limit: number): ValidatorFn {
    const validatorFn: (control: AbstractControl) => { [key: string]: any } | null = (control) => {
      const value = Number(control.value);
      if (isNaN(value) || value <= limit) {
        return { invalidValue: { value } };
      }

      return null;
    };
    return (control) => validatorFn(control);
  }

  static requiredIf(predicate: () => boolean = () => true): ValidatorFn {
    return predicate() ? Validators.required : null;
  }

  static matches(formControlName: string, confirmFormControlName: string): ValidatorFn {
    const validatorFn: (
      control: AbstractControl,
      confirmControl: AbstractControl
    ) => { [key: string]: any } | null = (control, confirmControl) => {
      if (!control && !confirmControl) {
        return null;
      }
      const result = control.value === confirmControl.value ? null : { unmatching: true };
      return result;
    };

    return (control: AbstractControl) =>
      validatorFn(control.get(formControlName), control.get(confirmFormControlName));
  }

  static notDuplicatedIdentifier(
    verifyIdentifier: (identifier: string) => Observable<HttpResponse<void>>
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value as string;
      if (value && value !== '') {
        if (value.match(this.identifierRegex)) {
          return verifyIdentifier(value).pipe(
            map((response) => {
              if (response.ok) {
                return { duplicated: { value } };
              } else {
                return null;
              }
            }),
            catchError((v) => of(null))
          );
        }
      }
      return of(null);
    };
  }
}

export class EceosValidatorsUtils {
  static isValid(obj: any): boolean {
    return obj ? obj.isValid() : false;
  }

  static isValidOptional(obj: any): boolean {
    return obj ? obj.isValid() : true;
  }

  static listIsValidOptional<T>(obj: any[], deserializer: (o: any) => T): boolean {
    return obj || obj.length === 0 ? obj
      .map(it => deserializer(it))
      .filter(it => !EceosValidatorsUtils.isValidOptional(it))
      .length === 0
      : true;
  }
}
