import { DomainEntity, SgvJson, SgvId } from '@eceos/arch';
import { Interest, CompoundInterest, SimpleInterest } from './interest';
import { RatePolicy } from '../rate-policies';
import { InstallmentPolicyFiscalData } from './installment-policy-fiscal-data';

export class InstallmentPolicySummary implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public minimumValue: number = 0.01,
    public maximum: number = 0
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): InstallmentPolicySummary {
    return SgvJson.from.simple(data, InstallmentPolicySummary);
  }
}

export class InstallmentPolicy implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public maximum: number = 0,
    public maximumWithoutInterest: number = 0,
    public minimumValue: number = 0.01,
    public interest: Interest = new CompoundInterest(),
    public ratePolicy: RatePolicy = new RatePolicy(),
    public weekDaysOnly: boolean = false,
    public fiscalData: InstallmentPolicyFiscalData = new InstallmentPolicyFiscalData()
  ) {}
  readonly isActive = true;
  toJson() {
    return SgvJson.to.simple(this, {
      interest: SgvJson.to.simple(this.interest),
      ratePolicy: SgvJson.to.simple(this.ratePolicy),
      fiscalData: SgvJson.to.simple(this.fiscalData)
    });
  }

  static fromJson(data: any): InstallmentPolicy {
    return SgvJson.from.simple(data, InstallmentPolicy, {
      interest: Interest.fromJson(data.interest),
      ratepolicy: RatePolicy.fromJson(data.ratePolicy),
      fiscalData: InstallmentPolicyFiscalData.fromJson(data.fiscalData)
    });
  }
}
