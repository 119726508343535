import { Injectable } from '@angular/core';
import { AnalyticsService, ApiService, CubeDimension, CubeMeasure } from '@eceos/arch';

@Injectable({ providedIn: 'root' })
export class NfePaymentsCube extends AnalyticsService {
  constructor(api: ApiService) {
    super(api.analytics.path('/nfePayments'));
  }

  static DIMENSIONS = {
    PAYMENT_METHOD: new CubeDimension('paymentMethod'),
    FINALITY: new CubeDimension('finality'),
    CLIENT: {
      NAME: new CubeDimension('clientName'),
      CIVIL_STATE: new CubeDimension('clientCivilState'),
      DISTRICT: new CubeDimension('clientDistrict'),
      ZIP_CODE: new CubeDimension('clientZipCode')
    },
    MOVEMENT: {
      YEAR: new CubeDimension('movement_year'),
      YEAR_MONTH: new CubeDimension('movement_month'),
      YEAR_MONTH_DAY: new CubeDimension('movement_day'),
      WEEK_DAY: new CubeDimension('movement_weekday_only'),
      MONTH_DAY: new CubeDimension('movement_monthday_only'),
      YEAR_DAY: new CubeDimension('movement_yearday_only'),
      MONTH: new CubeDimension('movement_month_only'),
      HOUR: new CubeDimension('movement_hour_only'),
      YEAR_MONTH_DAY_HOUR: new CubeDimension('movement_hour'),
      YEAR_MONTH_DAY_HOUR_MINUTE: new CubeDimension('movement_minute'),
      YEAR_MONTH_DAY_HOUR_MINUTE_SECOND: new CubeDimension('movement_second')
    }
  };

  static MEASURES = {
    PAYMENT_VALUE: new CubeMeasure('paymentValue')
  };
}
