import { HttpParams } from '@angular/common/http';
import { Filter } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainEntity } from '../domain/domain-entity';
import { JsonEntity } from '../domain/json-utils';
import { ApiPath, RequestMetadata, RequestMetadataUtils } from './../api/api.service';
import { ReadonlyRepositoryImpl } from './readonly-repository-impl';
import { Repository } from './repository';
import { InterceptorConfig } from '../interceptors';

export class RepositoryImpl<S extends JsonEntity, T extends DomainEntity>
  extends ReadonlyRepositoryImpl<S, T>
  implements Repository<S, T> {
  constructor(
    api: ApiPath,
    summaryType: { fromJson(json: any): S },
    type: { fromJson(json: any): T },
    filters?: Filter[],
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ) {
    super(api, summaryType, type, filters, metadata);
  }

  insert(
    value: T,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T> {
    return this.api
      .postJson(value.toJson(), {
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(o => this.type.fromJson(o)));
  }

  update(
    value: T,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T> {
    return this.api
      .path(value.id)
      .putJson(value.toJson(), { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(o => this.type.fromJson(o)));
  }

  activate(
    value: T,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T> {
    return this.api
      .path(value.id)
      .patchJson(
        { isActive: true },
        { metadata: RequestMetadataUtils.merge(metadata, this.metadata) }
      )
      .pipe(map(o => this.type.fromJson(o)));
  }

  delete(
    value: T,
    temp = false,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<boolean> {
    const params = new HttpParams().set('temp', `${temp}`);
    return this.api
      .path(value.id)
      .delete({ params, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(() => true));
  }
}
