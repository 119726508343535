import { SgvId, SgvJson, DomainEntity } from '@eceos/arch';
import { OperatableCategorySummary } from '../../operatables';

export class VaccineTypeSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    readonly name = '',
    readonly productCategory: OperatableCategorySummary = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      productCategory: this.productCategory ? this.productCategory.toJson() : null
    });
  }

  static fromJson(json: any): VaccineTypeSummary {
    return json
      ? SgvJson.from.simple(json, VaccineTypeSummary, {
          productCategory: OperatableCategorySummary.fromJson(json.productCategory)
        })
      : null;
  }
}
