export class CivilState {
  public static MARRIED = new CivilState('MARRIED', 'Casado', true);
  public static SINGLE = new CivilState('SINGLE', 'Solteiro');
  public static DIVORCED = new CivilState('DIVORCED', 'Divorciado');
  public static WIDOWED = new CivilState('WIDOWED', 'Viúvo');
  public static STABLE_UNION = new CivilState('STABLE_UNION', 'União Estável', true);

  private constructor(public readonly name: string, public readonly label: string, public readonly allowSpouse: boolean = false) {}

  public static values(): CivilState[] {
    return [CivilState.MARRIED, CivilState.SINGLE, CivilState.DIVORCED, CivilState.WIDOWED, CivilState.STABLE_UNION];
  }

  public static get(name: String): CivilState {
    const civilState = CivilState.values().find(v => v.name === name);
    if (!civilState) {
      const err = new Error(`${name} CivilState is not mapped!`);
      console.error(err);
      throw err;
    }
    return civilState;
  }
}
