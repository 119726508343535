import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { VaccineApplicationComponent } from './application/vaccine-application.component';
import { AppliedVaccinesComponent } from './applied/applied-vaccines.component';
import { PendingVaccinesComponent } from './pending/pending-vaccines.component';
import { VaccinesRoutes } from './vaccines.routes';

@NgModule({
  imports: [CommonModule, SharedModule, VaccinesRoutes],
  declarations: [PendingVaccinesComponent, AppliedVaccinesComponent, VaccineApplicationComponent]
})
export class VaccinesModule {}
