import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom, Observable } from 'rxjs';

export interface LoginValidator {
  validateLogin(username, password): Observable<LoginResult> | Promise<LoginResult>;
}

export class LoginResult {
  constructor(public readonly ok: boolean, public readonly message: string) {}

  static success(message = 'Login efetuado com sucesso'): LoginResult {
    return new LoginResult(true, message);
  }

  static fail(message: string = 'Falha ao efetuar login') {
    return new LoginResult(false, message);
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  @Input() usernameInputLabel = 'Usuário';

  @Input() validator: LoginValidator;

  @Input() signUpPath: string;

  @Input() forgotPasswordPath: string;

  loading = false;

  @ViewChild('username', { static: true }) private usernameInput: ElementRef;

  @ViewChild('password', { static: true }) private passwordInput: ElementRef;


  constructor(private snackbar: MatSnackBar) {}

  get canSignUp(): boolean {
    return this.signUpPath && this.signUpPath.length > 0;
  }

  get canRecoveryPassword(): boolean {
    return this.forgotPasswordPath && this.forgotPasswordPath.length > 0;
  }
  

  @Input()
  set username(username: string) {
    if (this.usernameInput.nativeElement.value !== username) {
      this.usernameInput.nativeElement.value = username || '';
      if (username) {
        this.passwordInput.nativeElement.focus();
      }
    }
  }

  ngOnInit() {}


  async submitLogin(username: string, password: string) {
    this.loading = true;
    try {
      const validateLogin = this.validator.validateLogin(username, password);
      const loginResult = await (validateLogin instanceof Observable
        ? lastValueFrom(validateLogin)
        : validateLogin);

      if (loginResult && !loginResult.ok) {
        this.showMessage(loginResult.message || 'Falha ao efetuar o login');
      }
    } catch (e) {
      console.error(e);
      this.showMessage(e.message || 'Falha ao efetuar o login');
    } finally {
      this.loading = false;
    }
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }
}
