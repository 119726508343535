import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class OperatableCategorySummary implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly name: string = '',
    readonly fullName: string = ''
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): OperatableCategorySummary {
    return json ? SgvJson.from.simple(json, OperatableCategorySummary) : null;
  }
}
