import { SgvJson, SgvId } from '@eceos/arch';

export class ProfileSumary {
  constructor(public readonly id = SgvId.gen(), public name = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ProfileSumary {
    return json ? SgvJson.from.simple(json, ProfileSumary) : null;
  }
}
