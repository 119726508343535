import { JsonEntity, SgvJson } from '@eceos/arch';
import { MathUtils } from '@eceos/common-utils';
import { MonthlyPeriodicity, Periodicity } from '../core/periodicities/periodicity';
import { ParcelSimulator, InstallmentPolicySummary } from '../financial';
import { FinancialDocument } from '../financial/documents';

export class PaymentDocumentValue implements JsonEntity {
  constructor(public document: FinancialDocument = null, public value = 0) {}

  get isValid(): boolean {
    return this.document && this.value && this.value > 0;
  }

  public toJson(): any {
    return SgvJson.to.simple(this, {
      document: this.document ? this.document.toJson() : null
    });
  }
  static fromJson(json: any): PaymentDocumentValue {
    return json
      ? SgvJson.from.simple(json, PaymentDocumentValue, {
          document: FinancialDocument.fromJson(json.document)
        })
      : null;
  }
}
export interface PaymentTable {
  getPaid(): PaymentDocumentValue[];
  newPaid(): PaymentDocumentValue;
  removePaid(pv: PaymentDocumentValue): void;
  getValidPaids(): PaymentDocumentValue[];

  getTotalPaid(): number;
  hasTotalPaid(): boolean;
  getPendingPaid(total: number): number;
  hasPendingPaid(total: number): boolean;
}

export interface PaybackTable {
  getChange(): PaymentDocumentValue[];
  newChange(): PaymentDocumentValue;
  removeChange(pv: PaymentDocumentValue): void;
  getValidChanges(): PaymentDocumentValue[];

  getTotalChange(): number;
  hasTotalChange(): boolean;
  getPendingChange(total: number): number;
  hasPendingChange(total: number): boolean;
}

export abstract class PaymentForm implements JsonEntity {
  abstract toJson(): any;

  abstract isFullyPaid(total: number): boolean;

  static fromJson(data: any): PaymentForm {
    if (data) {
      switch (data.type) {
        case 'deferred':
          return DeferredPaymentForm.fromJson(data);
        case 'cash':
          return CashPaymentForm.fromJson(data);
      }
    }
    return void 0;
  }
}

export class CashPaymentForm extends PaymentForm implements PaymentTable, PaybackTable {
  constructor(
    public paid: PaymentDocumentValue[] = [],
    public change: PaymentDocumentValue[] = []
  ) {
    super();
  }

  getPaid(): PaymentDocumentValue[] {
    return this.paid;
  }

  newPaid(): PaymentDocumentValue {
    const paid = new PaymentDocumentValue();
    this.paid.push(paid);
    return paid;
  }

  removePaid(pv: PaymentDocumentValue): void {
    this.paid = this.paid.filter(obj => obj !== pv);
  }

  getValidPaids(): PaymentDocumentValue[] {
    return this.paid.filter(p => p.isValid);
  }

  getTotalPaid(): number {
    return this.getValidPaids()
      .map(dv => dv.value)
      .reduce((v1, v2) => v1 + v2, 0);
  }

  hasTotalPaid(): boolean {
    return this.getTotalPaid() > 0;
  }

  getPendingPaid(total: number): number {
    const pendingPaid = total - this.getTotalPaid();
    return pendingPaid > 0 ? MathUtils.round(pendingPaid) : 0;
  }

  hasPendingPaid(total: number): boolean {
    return this.getPendingPaid(total) > 0;
  }

  getChange(): PaymentDocumentValue[] {
    return this.change;
  }

  newChange(): PaymentDocumentValue {
    const change = new PaymentDocumentValue();
    this.change.push(change);
    return change;
  }

  removeChange(pv: PaymentDocumentValue): void {
    this.change = this.change.filter(obj => obj !== pv);
  }

  getValidChanges(): PaymentDocumentValue[] {
    return this.change.filter(c => c.isValid);
  }

  getTotalChange(): number {
    return this.getValidChanges()
      .map(dv => dv.value)
      .reduce((v1, v2) => v1 + v2, 0);
  }

  hasTotalChange(): boolean {
    return this.getTotalChange() > 0;
  }

  getPendingChange(total: number): number {
    const pendingChange = this.getTotalPaid() - total - this.getTotalChange();
    return pendingChange > 0 ? MathUtils.round(pendingChange) : 0;
  }

  hasPendingChange(total: number): boolean {
    return this.getPendingChange(total) > 0;
  }

  isFullyPaid(total: number): boolean {
    return this.getTotalPaid() - this.getTotalChange() === total;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'cash',
      paid: SgvJson.to.array(this.paid),
      change: SgvJson.to.array(this.change)
    });
  }

  static fromJson(data: any): CashPaymentForm {
    return SgvJson.from.simple(data, CashPaymentForm, {
      paid: SgvJson.from.array(data.paid, PaymentDocumentValue.fromJson).filter(v => v.value > 0),
      change: SgvJson.from
        .array(data.change, PaymentDocumentValue.fromJson)
        .filter(v => v.value > 0)
    });
  }
}

export class DeferredPaymentForm extends PaymentForm implements PaymentTable, PaybackTable {
  constructor(
    public installmentPolicy: InstallmentPolicySummary = null,
    public numberOfInstallments = 1,
    public firstExpiration: Date = null,
    public installmentValue: number = null,
    public periodicity: Periodicity = new MonthlyPeriodicity(),
    public paid: PaymentDocumentValue[] = [],
    public change: PaymentDocumentValue[] = []
  ) {
    super();
    if (!this.firstExpiration) {
      this.firstExpiration = new Date();
      this.firstExpiration.setMonth(this.firstExpiration.getMonth() + 1);
    }
  }

  getPaid(): PaymentDocumentValue[] {
    return this.paid;
  }

  getValidPaids(): PaymentDocumentValue[] {
    return this.paid.filter(p => p.isValid);
  }

  newPaid(): PaymentDocumentValue {
    const paid = new PaymentDocumentValue();
    this.paid.push(paid);
    return paid;
  }

  removePaid(pv: PaymentDocumentValue): void {
    this.paid = this.paid.filter(obj => obj !== pv);
  }

  getTotalPaid(): number {
    return this.getValidPaids()
      .map(dv => dv.value)
      .reduce((v1, v2) => v1 + v2, 0);
  }

  hasTotalPaid(): boolean {
    return this.getTotalPaid() > 0;
  }

  getPendingPaid(total: number): number {
    const pendingPaid = total - this.getTotalPaid();
    return pendingPaid > 0 ? MathUtils.round(pendingPaid) : 0;
  }

  hasPendingPaid(total: number): boolean {
    return this.getPendingPaid(total) > 0;
  }

  getChange(): PaymentDocumentValue[] {
    return this.change;
  }

  newChange(): PaymentDocumentValue {
    const change = new PaymentDocumentValue();
    this.change.push(change);
    return change;
  }

  removeChange(pv: PaymentDocumentValue): void {
    this.change = this.change.filter(obj => obj !== pv);
  }

  getValidChanges(): PaymentDocumentValue[] {
    return this.change.filter(c => c.isValid);
  }

  getTotalChange(): number {
    return this.getValidChanges()
      .map(dv => dv.value)
      .reduce((v1, v2) => v1 + v2, 0);
  }

  hasTotalChange(): boolean {
    return this.getTotalChange() > 0;
  }

  getPendingChange(total: number): number {
    const pendingChange = this.getTotalPaid() - total - this.getTotalChange();
    return pendingChange > 0 ? MathUtils.round(pendingChange) : 0;
  }

  hasPendingChange(total: number): boolean {
    return this.getPendingChange(total) > 0;
  }

  isFullyPaid(total: number): boolean {
    return Boolean(
      this.installmentPolicy &&
        this.numberOfInstallments > 0 &&
        this.firstExpiration &&
        this.periodicity &&
        this.periodicity.recurrences
    );
  }

  getDeferredValue(parcelValue: number): number {
    return this.numberOfInstallments * parcelValue;
  }

  getInstallmentValue(): number {
    return this.installmentValue;
  }

  hasInstallmentValue(): boolean {
    return this.installmentValue && this.installmentValue > 0;
  }

  getParcelSimulator(total: number): ParcelSimulator {
    return new ParcelSimulator(
      this.getPendingPaid(total),
      this.numberOfInstallments,
      this.firstExpiration,
      this.periodicity
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'deferred',
      periodicity: this.periodicity ? this.periodicity.toJson() : null,
      paid: SgvJson.to.array(this.paid),
      change: SgvJson.to.array(this.change),
      installmentPolicy: this.installmentPolicy ? this.installmentPolicy.toJson() : null
    });
  }

  static fromJson(data: any): DeferredPaymentForm {
    return SgvJson.from.simple(data, DeferredPaymentForm, {
      firstExpiration: new Date(data.firstExpiration),
      periodicity: Periodicity.fromJson(data.periodicity),
      paid: SgvJson.from.array(data.paid, PaymentDocumentValue.fromJson).filter(v => v.value > 0),
      change: SgvJson.from
        .array(data.change, PaymentDocumentValue.fromJson)
        .filter(v => v.value > 0),
      installmentPolicy: InstallmentPolicySummary.fromJson(data.installmentPolicy)
    });
  }
}
