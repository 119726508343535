export class TransmissionEnvironment {
  public static PRODUCTION = new TransmissionEnvironment('PRODUCTION', 'Produção');
  public static HOMOLOGATION = new TransmissionEnvironment('HOMOLOGATION', 'Homologação');
  
  private constructor(public readonly name: string, public readonly label: string) {}

  public static values(): TransmissionEnvironment[] {
    return [TransmissionEnvironment.PRODUCTION, TransmissionEnvironment.HOMOLOGATION];
  }

  public static get(name: String): TransmissionEnvironment {
    const transmissionEnvironment = TransmissionEnvironment.values().find(v => v.name === name);
    if (!transmissionEnvironment) {
      const err = new Error(`${name} TransmissionEnvironment is not mapped!`);
      console.error(err);
      throw err;
    }
    return transmissionEnvironment;
  }
}
