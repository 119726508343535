import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { DeliveryFiscalData } from './delivery-fiscal-data';
import { DeliveryResponsible, DeliveryWithoutResponsible } from './delivery-responsible';
import { DeliveryValueCalc, DeliveryValueWithoutCalc } from './delivery-value-calc';

export class DeliveryPolicy implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name = '',
    public fiscalData: DeliveryFiscalData = null,
    public responsible: DeliveryResponsible = new DeliveryWithoutResponsible(),
    public valueCalc: DeliveryValueCalc = new DeliveryValueWithoutCalc(),
    public version = 0,
    public isActive = true
  ) {}

  toSummary(): DeliveryPolicySummary {
    return new DeliveryPolicySummary(this.id, this.name);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      fiscalData: SgvJson.to.optional(this.fiscalData),
      responsible: this.responsible.toJson(),
      valueCalc: this.valueCalc.toJson()
    });
  }

  static fromJson(json: any): DeliveryPolicy {
    return json
      ? SgvJson.from.simple(json, DeliveryPolicy, {
          fiscalData: DeliveryFiscalData.fromJson(json.fiscalData),
          responsible: DeliveryResponsible.fromJson(json.responsible),
          valueCalc: DeliveryValueCalc.fromJson(json.valueCalc)
        })
      : null;
  }
}

export class DeliveryPolicySummary implements DomainEntity {
  constructor(readonly id: string = SgvId.gen(), public name = '') {}

  toJson(): string {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): DeliveryPolicySummary {
    return SgvJson.from.simple(json, DeliveryPolicySummary);
  }
}
