import { Observable } from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import { InterceptorConfig } from '../interceptors/interceptors.config';
import { retryExceptOnClientError } from './../../../../common-utils/src/lib/operators/custom-operators';
import { ApiPath, RequestMetadata, RequestMetadataUtils } from './../api/api.service';
import { JsonEntity } from './../domain/json-utils';
import { ReadonlyRepository } from './readonly-repository';
import { Actives } from './search-options';
import { Filter } from './search-options/filter';
import { PageData } from './search-options/page-data';
import { SortOrder } from './search-options/sort-order';
import { UrlSearchParamsBuilder } from './search-options/url-search-params-builder';

export class ReadonlyRepositoryImpl<S extends JsonEntity, T extends JsonEntity>
  implements ReadonlyRepository<S, T> {
  constructor(
    protected api: ApiPath,
    protected summaryType: { fromJson(json: any): S },
    protected type: { fromJson(json: any): T },
    protected filters?: Filter[],
    protected metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ) {}

  list(
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<S[]> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .build();
    return this.api
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((r) => r.map((j: any) => this.summaryType.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      );
  }

  listFull(
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T[]> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .summary(false)
      .build();
    return this.api
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((r) => r.map((j: any) => this.type.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      );
  }

  page(
    pageData: PageData,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<S[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .build();
    return this.api
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((r) => r.map((j: any) => this.summaryType.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      );
  }

  pageFull(
    pageData: PageData,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .summary(false)
      .build();
    return this.api
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((r) => r.map((j: any) => this.type.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      );
  }

  count(
    query: string = '',
    filters: Filter[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<number> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(this.applyFilter(filters))
      .actives(actives)
      .build();
    return this.api
      .path('size')
      .getText({ params: search, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(
        map((r) => r || '0'),
        map((text) => Number.parseInt(text, 10)),
        retryWhen(retryExceptOnClientError())
      );
  }

  find(
    id: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T> {
    return this.api
      .path(id)
      .getJson({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(
        map((r) => this.type.fromJson(r)),
        retryWhen(retryExceptOnClientError())
      );
  }

  findSummary(
    id: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<S> {
    const params = new UrlSearchParamsBuilder().summary(true).build();
    return this.api
      .path(id)
      .getJson({ params: params, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(
        map((r) => this.summaryType.fromJson(r)),
        retryWhen(retryExceptOnClientError())
      );
  }

  private applyFilter(filters: Filter[]): Filter[] {
    return [...filters, ...(this.filters || [])];
  }
}
