
export class CompositionIncomeType {

  public static INCOME_PER_JOB = new CompositionIncomeType('INCOME_PER_JOB', 'Renda por Trabalho');
  public static PRO_LABORE = new CompositionIncomeType('PRO_LABORE', 'Pró-Labore');
  public static INCOME_OF_SPOUSE = new CompositionIncomeType('INCOME_OF_SPOUSE', 'Renda do Cônjuge');
  public static PENSION = new CompositionIncomeType('PENSION', 'Pensão');
  public static OTHERS = new CompositionIncomeType('OTHERS', 'Outros');

  private constructor(
    public readonly name: string,
    public readonly label: string
  ) {}

  public static values(): CompositionIncomeType[] {
    return [
      CompositionIncomeType.INCOME_PER_JOB,
      CompositionIncomeType.PRO_LABORE,
      CompositionIncomeType.INCOME_OF_SPOUSE,
      CompositionIncomeType.PENSION,
      CompositionIncomeType.OTHERS
    ];
  }

  public static get(name: String): CompositionIncomeType {
    const type = CompositionIncomeType.values().find(v => v.name === name);
    if (!type) {
      const err = new Error(`${name} CompositionIncomeType is not mapped!`);
      console.error(err);
      throw err;
    }
    return type;
  }
}
