<app-error-page [color]="defaultColor">
  <div class="title">
    404 - Página não encontrada!
  </div>
  <div class="content">
    Desculpe, mas o recurso que você está procurando não foi encontrado.
  </div>
  <mat-card-actions class="actions">
    <button mat-button [routerLink]="['/']">Voltar para o início</button>
  </mat-card-actions>
</app-error-page>
 