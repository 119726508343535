import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ApiModule } from './api/api.module';
import { AuthModule } from './auth/auth.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AuthModule,
    ApiModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
  ],
  exports: [AuthModule, ApiModule],
  declarations: []
})
export class ArchModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ArchModule
  ) {}
}
