import { Action } from '@eceos/arch';

export class ProductAction implements Action {
    private constructor (public readonly id: string) {}
    
    toJson() {
        return this.id;
    }

    static readonly STOCK_ADJUSTMENT = new ProductAction('STOCK_ADJUSTMENT');
    static readonly SEE_COST_PRICE = new ProductAction('SEE_COST_PRICE');

    static readonly ALL = [
        ProductAction.STOCK_ADJUSTMENT,
        ProductAction.SEE_COST_PRICE
    ];
}