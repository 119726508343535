import { JsonEntity, SgvJson } from '@eceos/arch';
import { MathUtils } from '@eceos/common-utils';

export class PaymentDiscount implements JsonEntity {
  toJson(): any {
    return { type: 'nodiscount' };
  }
  public getValue(total: number): number {
    return 0;
  }
  public getPercent(total: number): number {
    return 0;
  }
  public applyOn(total: number): number {
    return 0;
  }
  public isEmpty(): boolean {
    return true;
  }
  static fromJson(json: any): PaymentDiscount {
    if (json && json.type) {
      switch (json.type) {
        case 'value': {
          return new PaymentValueDiscount(json.value);
        }
        case 'porcent': {
          return new PaymentPercentDiscount(json.value);
        }
      }
    }
    return new PaymentValueDiscount();
  }
}

export class PaymentPercentDiscount extends PaymentDiscount {
  public value = 0;

  constructor(value: number = 0) {
    super();
    this.value = value;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'porcent'
    });
  }

  public getValue(total: number) {
    return MathUtils.round((this.value / 100) * total);
  }

  public getPercent(total: number): number {
    return this.value;
  }

  public applyOn(total: number): number {
    return MathUtils.round(total - this.getValue(total));
  }

  public isEmpty(): boolean {
    return this.value === 0;
  }
}

export class PaymentValueDiscount extends PaymentDiscount {
  public value = 0;

  constructor(value: number = 0) {
    super();
    this.value = value;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'value'
    });
  }

  public getValue(total: number): number {
    return this.value >= total ? 0 : this.value;
  }

  public getPercent(total: number): number {
    return MathUtils.round((this.value / total) * 100);
  }

  public applyOn(total: number): number {
    return this.value >= total ? 0 : total - this.value;
  }

  public isEmpty(): boolean {
    return this.value === 0;
  }
}
