import { Action, Functionality, JsonEntity, SgvJson } from '@eceos/arch';
import { Feature } from '../feature';

export class Permission implements JsonEntity {
  constructor(
    readonly feature: Functionality = null,
    public canAccess = true,
    public allowedActions: Action[] = []
  ) {}

  canDo(...actions: Action[]): boolean {
    if (!this.canAccess) {
      return false;
    }
    for (const action of actions) {
      if (!this.allowedActions.includes(action)) {
        return false;
      }
    }
    return true;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      feature: this.feature.toJson(),
      allowedActions: SgvJson.to.array(this.allowedActions)
    });
  }

  static fromJson(json: any): Permission {
    if (!json) {
      return null;
    }
    const feature = Feature.fromJson(json.feature);

    return !feature
      ? null
      : SgvJson.from.simple(json, Permission, {
          feature: feature,
          allowedActions: SgvJson.from
            .array(json.allowedActions, a => feature.getActionById(a))
            .filter(a => Boolean(a))
        });
  }
}
