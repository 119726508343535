import { untilDestroyed } from 'ngx-take-until-destroy';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { VaccineApplicationsRepository, VaccineApplicationSummary } from '@eceos/domain';
import { interval } from 'rxjs';

@Component({
  selector: 'vpda-pending-vaccines',
  templateUrl: './pending-vaccines.component.html',
  styleUrls: ['./pending-vaccines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingVaccinesComponent implements OnInit, OnDestroy {
  displayedColumns = ['number', 'date', 'patient', 'gender', 'requestedVaccines', 'timeOfWait'];

  vaccineApplications = new MatTableDataSource();

  loading = false;

  constructor(
    private vaccineApplicationRepository: VaccineApplicationsRepository,
    private snackBar: MatSnackBar,
    private router: Router,
 
  ) {
    this.searchPendingVaccines();
    this.autoRefreshTable();
  }

  ngOnInit() {
  }

  ngOnDestroy() {}

  private autoRefreshTable(): void {
    interval(10000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.searchPendingVaccinesAndLoad(false);
      });
  }

  private searchPendingVaccines(): void {
    this.searchPendingVaccinesAndLoad(true);
  }

  private searchPendingVaccinesAndLoad(load: boolean): void {
    if (!this.loading) {
      this.loading = load;

      this.vaccineApplicationRepository.listPending().subscribe(
        results => {
          this.vaccineApplications.data = results;
          this.loading = false;
        },
        e => {
          this.loading = false;
          console.log(e);
        },
        () => (this.loading = false)
      );
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    this.vaccineApplications.filterPredicate = (data: VaccineApplicationSummary, filter: string) =>
      data.patient.name.toLowerCase().includes(filter.toLowerCase()) ||
      data.vaccinesRequested.toLowerCase().includes(filter.toLowerCase()) ||
      data.number
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase());

    this.vaccineApplications.filter = filterValue;
  }

  openPending(pending: VaccineApplicationSummary) {
    if (!this.loading) {
      this.loading = true;
      this.snackBar.open('Abrindo Vacinação Nº' + pending.number + '.', null, { duration: 2000 });
      this.router.navigate(['/vaccines', pending.id]);
      this.loading = false;
    }
  }
}
