import { SgvId, SgvJson } from '@eceos/arch';
import { StockLocationSummary } from '../products/stock/stock-location-summary';
import { LegalEntityDocuments } from '../core/documents';

export class Enterprise {
  constructor() {}
}

export class EnterpriseSummary extends StockLocationSummary {
  constructor(
    public readonly id: string = SgvId.gen(),
    public name = '',
    public identifier = '',
    public cnpj = '',
    public isActive = true
  ) {
    super(name);
  }

  get maskedCnpj(): string {
    return LegalEntityDocuments.MASK.apply(this.cnpj);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'enterprise'});
  }

  static fromJson(json: any): EnterpriseSummary {
    return json ? SgvJson.from.simple(json, EnterpriseSummary) : null;
  }
}
