import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Gender } from '../core';
import { Address, BrazilianAddress, SimpleForeignAddress } from '../core/address';
import {
  Documents,
  ForeignPersonDocuments,
  LegalEntityDocuments,
  NaturalPersonDocuments
} from '../core/documents';
import { MailContact } from '../core/mail-contact';
import { PhoneContact } from '../core/phone-contact';
import { PriceTable } from '../price-tables';
import { Vendor } from '../vendors/vendor';
import { CivilState } from './civil-state';
import { CompositionIncome } from './composition-incomes/composition-income';
import { FinancialData } from './financial/financial-data';
import { Spouse } from './spouse';

export class ClientSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly cnp = ''
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ClientSummary {
    return json ? SgvJson.from.simple(json, ClientSummary) : null;
  }
}

export class Client implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public documents: Documents = new NaturalPersonDocuments(),
    public gender: Gender = Gender.NOT_INFORMED,
    public birth: Date = null,
    public registrationDate: Date = new Date(),
    public civilState: CivilState = CivilState.SINGLE,
    public financialData: FinancialData = new FinancialData(),
    public spouse: Spouse = null,
    public address: Address = new BrazilianAddress(),
    public emails: MailContact[] = [],
    public phones: PhoneContact[] = [],
    public vendor: Vendor = null,
    public priceTable: PriceTable = null,
    public compositionIncomes: CompositionIncome[] = [],
    public details: string = '',
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  get isNaturalPerson(): boolean {
    return this.documents && this.documents instanceof NaturalPersonDocuments;
  }

  get isLegalEntity(): boolean {
    return this.documents && this.documents instanceof LegalEntityDocuments;
  }

  get isForeignPerson(): boolean {
    return this.documents && this.documents instanceof ForeignPersonDocuments;
  }

  get incomesValue() {
    return this.compositionIncomes.map(c => c.value).reduce((v1, v2) => v1 + v2, 0);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      gender: this.gender.name,
      civilState: this.civilState.name,
      financialData: this.financialData ? this.financialData.toJson() : null,
      spouse: this.spouse ? this.spouse.toJson() : null,
      address: this.address ? this.address.toJson() : null,
      documents: this.documents ? this.documents.toJson() : null,
      vendor: this.vendor ? this.vendor.toJson() : null,
      priceTable: this.priceTable ? this.priceTable.toJson() : null,
      compositionIncomes: SgvJson.to.array(this.compositionIncomes),
      contacts: [
        ...this.emails
          .map(c => c.value)
          .filter(c => c && c !== '')
          .map(c => ({
            type: 'EMAIL',
            detail: c
          })),
        ...this.phones
          .map(c => c.value)
          .filter(c => c && c !== '')
          .map(c => ({
            type: 'TELEFONE',
            detail: c
          }))
      ]
    });
  }

  static naturalPerson(): Client {
    const entity = new Client();
    entity.documents = new NaturalPersonDocuments();
    return entity;
  }

  static legalEntity(): Client {
    const entity = new Client();
    entity.documents = new LegalEntityDocuments();
    return entity;
  }

  static foreignPerson(): Client {
    const entity = new Client();
    entity.documents = new ForeignPersonDocuments();
    entity.address = new SimpleForeignAddress();
    return entity;
  }

  static fromJson(json: any): Client {
    return SgvJson.from.simple(json, Client, {
      financialData: FinancialData.fromJson(json.financialData),
      spouse: json.spouse ? Spouse.fromJson(json.spouse) : null,
      address: json.address ? Address.fromJson(json.address) : null,
      documents: json.documents ? Documents.fromJson(json.documents) : null,
      vendor: json.vendor ? Vendor.fromJson(json.vendor) : null,
      priceTable: json.priceTable ? PriceTable.fromJson(json.priceTable) : null,
      compositionIncomes: SgvJson.from.array(json.compositionIncomes, CompositionIncome.fromJson),
      gender: json.gender ? Gender.get(json.gender) : null,
      civilState: json.civilState ? CivilState.get(json.civilState) : null,
      registrationDate: json.registrationDate ? new Date(json.registrationDate) : null,
      birth: json.birth ? new Date(json.birth) : null,
      emails: json.contacts
        ? json.contacts
            .filter((c: any) => c && c.detail && c.detail !== '')
            .filter((c: any) => c.type === 'EMAIL' || c.type === 'EMAIL_NFE')
            .map((c: any) => new MailContact(c.detail.trim()))
        : [],
      phones: json.contacts
        ? json.contacts
            .filter((c: any) => c && c.detail && c.detail !== '')
            .filter((c: any) => c.type === 'TELEFONE' || c.type === 'CELULAR')
            .map((c: any) => new PhoneContact(c.detail.replace(/\D/g, '').trim()))
        : []
    });
  }
}
