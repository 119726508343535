<vpda-layout>
  <mat-card class="titleCard">
    <mat-card-header>
      <mat-card-title>CONFIGURAÇÕES</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <vpda-printer-settings></vpda-printer-settings>
    </mat-card-content>
  </mat-card>
</vpda-layout>
