import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class BodySiteSummary implements DomainEntity {
  constructor(readonly id = SgvId.gen(), readonly name: string = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): BodySiteSummary {
    return json ? SgvJson.from.simple(json, BodySiteSummary) : null;
  }
}
