export class ReportFormat {
  static PDF = new ReportFormat('PDF', 'pdf', 'PDF');
  static ESCPOS = new ReportFormat('ESCPOS', 'escpos', 'ESC/POS');

  private constructor(
    readonly name: string,
    readonly value: string,
    readonly description: string
  ) {}

  static get(name: string): ReportFormat {
    return this.values().find(it => it.name === name);
  }

  static values(): ReportFormat[] {
    return [ReportFormat.PDF, ReportFormat.ESCPOS];
  }
}
