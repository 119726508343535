import { SettingsComponent } from './settings.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@eceos/arch';


export const routes: Routes = [
  {
      path: 'settings',
      component: SettingsComponent,
      canActivate: [AuthGuard]
  }];

export const SettingsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
