export class StockType {
    
    public static STOCK_BY_LOCATION = new StockType("STOCK_BY_LOCATION", 'Por localização');
    public static WITHOUT_STOCK_DATA = new StockType("WITHOUT_STOCK_DATA", 'Não controlado');

    private constructor(
        public readonly name: string,
        public readonly label: string
    ) { }

    public static values(): StockType[] {
        return [
            StockType.STOCK_BY_LOCATION,
            StockType.WITHOUT_STOCK_DATA,
        ];
    }

    public static get(name: String): StockType {
        const type = StockType.values().find(v => v.name === name);
        if (!type) {
            const err = new Error(`${name} StockType is not mapped!`);
            console.error(err);
            throw err;
        }
        return type;
    }
}