export class SaleState {
  static PENDENTE = new SaleState('PENDENTE', 'Pendente');
  static FINALIZADA = new SaleState('FINALIZADA', 'Finalizada');
  static CANCELADA = new SaleState('CANCELADA', 'Cancelada');

  private constructor(readonly name: string, readonly label: string) {}

  static get(name: string): SaleState {
    const state = SaleState.values().find(v => v.name === name);
    if (!state) {
      const err = new Error(`${name} SaleState is not mapped!`);
      console.error(err);
      throw err;
    }
    return state;
  }

  static getOrNull(name: string): SaleState {
    return SaleState.values().find(v => v.name === name);
  }

  static values(): SaleState[] {
    return [SaleState.PENDENTE, SaleState.FINALIZADA, SaleState.CANCELADA];
  }
}
