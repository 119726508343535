import { Component, OnInit } from '@angular/core';
import { CookieService } from '@eceos/common-components';

@Component({
  selector: 'vpda-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    this.cookieService.checkCookieConsent();
  }
}
