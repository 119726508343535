import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class FiscalDocumentSummary implements DomainEntity {
  public id = SgvId.gen();

  public model = '';

  public status = '';

  get authorized(): boolean {
    return this.status === 'AUTHORIZED';
  }

  get processing(): boolean {
    return this.status === 'PROCESSING';
  }

  get isNfe() {
    return this.model === 'nfe';
  }

  get isNfce() {
    return this.model === 'nfce';
  }

  get isNfse() {
    return this.model === 'nfse';
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, FiscalDocumentSummary);
  }
}
