import { PageNotFoundComponent } from '@eceos/common-pages';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'vaccines', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy'
});
